import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router";
import Icon from '../../../helpers/Icon'
import { checkIsActive } from '../../../helpers/MenuHelpers';

export default function MenuItem({name, to, iconClass}) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "active" : "";
    }

    return (
        <>
            <li className={`nav-item`}>
                <Link className={`nav-link  ${getMenuItemActive(to)}`}  to={to}>
                    <Icon className={iconClass} />
                    {name}
                    {/* si tiene hijo agregar icono con flecha */}
                    {/* e impirmir hijos */}
                </Link>
            </li>
        </>
    )
}
