import React, { useContext } from 'react';
import { AuthContext } from '../../../helpers/auth/AuthContext';
import MenuItem from './MenuItem';

export const Menu = () => {

  const { user } = useContext(AuthContext);
  return (
    <>
    <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <MenuItem name="Dashboard" to="/" iconClass="nav-icon fas fa-tachometer-alt" />
          {(user.role === 'admin') ? (
          <>
            <MenuItem name="Dtes Emitidos" to="/dtesEmitidos" iconClass="nav-icon fas fa-file-alt" />
            <MenuItem name="Dtes Recibidos" to="/dtesRecibidos" iconClass="nav-icon fas fa-file-alt" />
            <MenuItem name="Folios" to="/folios" iconClass="nav-icon fas fa-file-alt" />
            <li className="nav-header">Configuraciones</li>
            <MenuItem name="Email" to="/configuracionEmail" iconClass="nav-icon fas fa-cogs" />
            <MenuItem name="Configuración" to="/configuracion" iconClass="nav-icon fas fa-cogs" />
            <MenuItem name="Usuarios" to="/usuarios" iconClass="nav-icon fas fa-users" />
          </>
          ):null}

          {(user.role === 'ventas') ? (
          <>
            <MenuItem name="Dtes Emitidos" to="/dtesEmitidos" iconClass="nav-icon fas fa-file-alt" />
            <MenuItem name="Folios" to="/folios" iconClass="nav-icon fas fa-file-alt" />
          </>
          ):null}
          {(user.role === 'compras') ? (
          <>
            <MenuItem name="Dtes Recibidos" to="/dtesRecibidos" iconClass="nav-icon fas fa-file-alt" />
          </>
          ):null}

          {
            (user.role !== 'admin') &&
            <>
              <li className="nav-header">Configuraciones</li>
              <MenuItem name="Usuarios" to="/usuarios" iconClass="nav-icon fas fa-users" />
            </>
          }

          <li className="nav-header">Logout</li>
          <MenuItem name="Cerrar Session" to="/logout" iconClass="nav-icon fas fa-sign-out-alt" />
        </ul>
      </nav>
    </>
  );
}
