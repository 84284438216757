import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import {ModalSetUsuario} from './ModalSetUsuario';
import ModalUpdateUsuario from './ModalUpdateUsuario';
import ModalLayaut from '../modal/ModalLayaut';
import {GetUsers} from '../../components/common/Crud/Users'
import { AuthContext } from '../../helpers/auth/AuthContext';

export const UsuariosPage = props => {

	const [modalUpdateShow, setModalUpdateShow] = useState(false);
	const [modalSetShow, setModalSetShow] = useState(false);
  const [userList, setUserList] = useState([])
  const [user, setuser] = useState(false)
  const [status, setStatus] = useState(false);
  const [alertClass, setAlertClass] = useState('info');
  
  const {user: userContext} = useContext(AuthContext);
  
  useEffect(() => {

    return () => {
      //cleanup
    }
  }, [])

  const columns = [
    {
      name: 'Nombre',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Email',
      selector: 'email',
      sortable: true,
    },
    {
      name: 'Activo',
      selector: 'email',
      sortable: true,
      cell: row => <> {row.pivot.active ? 'Si' : 'No' } </>,

    },
    {
      name: 'Tipo',
      selector: 'userType_code',
      sortable: true,
    },
    {
      name: 'Api Key',
      selector: 'api_token',
      sortable: true,
    },
    {
      cell: row => {
        if(userContext.role == 'admin' || row.id == userContext.id) {
          return (
            <div>
              <Button
                variant="primary"
                size="xs"
                onClick={ () => {
                  setuser(row);
                  setModalUpdateShow(true);
                }}
              >
                Modificar
              </Button>
            </div>
          )
        }
      },
      name: 'Acciones',
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  const getUsers = () => {
    GetUsers()
      .then(({data}) => {
        setUserList(data);
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de conexion con la API');
      });
  }

  useEffect(() => {
    getUsers();
}, [modalSetShow, modalUpdateShow])

  //traerme los usuarios de la empresa

	return (
		<>
	  	<div className="container-fluid">
				<div className="row">
			  	<div className="col-12">
						<div className="card">
				  		<div className="card-header container-fluid">
					  		<div className="row">
    					    	<div className="col-11">
    					        	<h3 className="w-75 p-3">Usuarios</h3>
    					    	</div>
                    {/* <div className={`col-1 float-right ${user.userType_code !== 'admin' ? 'd-none' : ''}`}>
    					        	<Button variant="primary" onClick={() => setModalSetShow(true)}>Agregar</Button>
    					    	</div> */}
                    <Button variant="primary" disabled={userContext.role == 'admin' ? false : true} onClick={() => setModalSetShow(true)}>Agregar</Button>
    						</div>
				  		</div>
				  		<div className="card-body">
                {
                  status ? (
                      <div className={`alert alert-${alertClass} alert-dismissible`}>
                          {status}
                      </div>
                  ) : null
                }
								<div className="row">
									<div className="col">
										<DataTable
								  	 columns={columns}
								  	 data={userList}
                     noDataComponent="No existen datos para mostrar"
										/>
				  				</div>
				  			</div>
				  		</div>
						</div>
			  	</div>
				</div>
	  	</div>
			<ModalLayaut title={"Actualizar Usuario"} show={modalUpdateShow} onHide={() => setModalUpdateShow(false)}>
        <ModalUpdateUsuario
					user={user}
				/>
      </ModalLayaut>
      <ModalLayaut title={"Crear Usuario"} show={modalSetShow} onHide={() => setModalSetShow(false)}>
        <ModalSetUsuario
					user={user}
				/>
      </ModalLayaut>
		</>
	)
}
