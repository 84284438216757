import { Field, FormikProvider, useFormik } from "formik";
import { useEffect, useState, Link } from "react";
import { Button} from "react-bootstrap";
import {
  TemplatesPdf,
  SetCompanySetting,
  SetCompanyData,
  setCertificateCompany,
  setLogoCompany,
  GetPdfExample
} from '../../components/common/Crud/Companies';
import { Spinner } from '../../helpers/Icon';
import { Schema } from "../modal/components/configuracion/Validation";


export const ModalConfiguracion = ({companySetting, setReload}) => {

  const [loading, setLoading] = useState(false);
  const [templatesPdf, setTemplatesPdf] = useState([]);
  const [logo, setLogo] = useState(false);
  const [certificado, setCertificado] = useState(false);
  const [alertClass, setAlertClass] = useState('danger');
  const [srcLogo, setSrcLogo] = useState(companySetting[0].company.logo);

  const initialValues = {
    id: companySetting[0].id,
    company: {
      ecoact: companySetting[0].company.ecoact ?? null,
      resolution_date: companySetting[0].company.resolution_date ?? null,
      resolution_number: companySetting[0].company.resolution_number ?? null,
      address: companySetting[0].company.address ?? null,
      business_activity: companySetting[0].company.business_activity ?? null,
      certificate_pass: companySetting[0].company.certificate_pass ?? null,
      district: companySetting[0].company.district ?? null,
    },
    templatePdf_id: companySetting[0].templatePdf_id ?? null,
    digital_certificate: false,
    logo: false,
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);

      const certificadoForm = new FormData();
      certificadoForm.append('certificado', certificado);

      const logoForm = new FormData();
      logoForm.append('logo', logo);

      SetCompanySetting(values)
      .then( ({data}) => {
				if(data.success){
          setReload();
          setAlertClass('info');
          setStatus('Información Cargada correctamente.');
          return SetCompanyData(values.company)
				}else{
          return Promise.reject()
				}

			})
      .then( ({data}) => {
        if(data.success){
          return (certificado ? setCertificateCompany(certificado) : Promise.resolve(true));
				}else{
          return Promise.reject()
				}
      })
      .then( (data) => {
          return (logo ? setLogoCompany(logoForm) : Promise.resolve(true));
      })
			.catch((e) => {
        setAlertClass('danger');
        setStatus('Error al actualizar configuración');
      });

      disableLoading();
      setSubmitting(false);
    }
  });

  const getTemplatesPdf = () => {
		TemplatesPdf()
			.then(({data}) => {
				setTemplatesPdf([...data]);
			})
			.catch(() => {
        setAlertClass('danger');
        formik.setStatus('Error de conexion con la API');
      });
  }

  useEffect(() => {
    getTemplatesPdf();
  }, []);

  const handleSetLogo = (e) =>{
    var reader  = new FileReader();

    reader.onloadend = function () {
      setSrcLogo(reader.result);
    }

    reader.readAsDataURL(e.currentTarget.files[0]);

    setLogo(e.currentTarget.files[0]);
  }

  const handleSetCertificado = (e) =>{
    setCertificado(e.currentTarget.files[0]);
  }

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    if (typeof(formik.errors.company) !== 'undefined'){
      if (formik.errors.company[fieldname]) {
        return "is-invalid";
      }

      if (!formik.errors.company[fieldname]) {
        return "is-valid";
      }
    }

    return "";
  };

  return (
    <>
		<FormikProvider value={formik}>
      		<div>
      		  <form onSubmit={formik.handleSubmit}>
      		    {
      		        formik.status ? (
      		            <div className={`alert alert-${alertClass} alert-dismissible`}>
      		                {formik.status}
      		            </div>
      		        ) : null
      		    }
      		    <div className="container">
      		      <div className="row">

                  <div className="col-4">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Codigo de Actividad</label>
      		            <div className="input-group">
      		                <Field className={`form-control ${getInputClasses( "ecoact" )}`} type ="text" name="company.ecoact" />
      		                <Field style={{display:'none'}} type ="text" name="id" />
      		            </div>
      		          </div>
      		        </div>
                  <div className="col-6">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Descripción de Actividad</label>
      		            <div className="input-group">
      		                <Field className={` form-control ${getInputClasses( "business_activity" )}`} type ="text" name="company.business_activity" />
      		            </div>
      		          </div>
      		        </div>

                  <div className="col-4">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Fecha de Resolución</label>
      		            <div className="input-group">
      		                <Field className={` form-control ${getInputClasses( "resolution_date" )}`} type ="date" name="company.resolution_date" />
      		            </div>
      		          </div>
      		        </div>
                  <div className="col-3">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Numero de Resolución</label>
      		            <div className="input-group">
      		                <Field className={`form-control ${getInputClasses( "resolution_number" )}`} type ="text" name="company.resolution_number" />
      		            </div>
      		          </div>
      		        </div>
                  <div className="col-3">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Clave certificado Digital</label>
      		            <div className="input-group">
      		                <Field className={`form-control ${getInputClasses( "certificate_pass" )}`} type ="text" name="company.certificate_pass" />
      		            </div>
      		          </div>
      		        </div>

                  <div className="col-6">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Dirección</label>
      		            <div className="input-group">
      		                <Field className={`form-control ${getInputClasses( "address" )}`} type ="text" name="company.address" />
      		            </div>
      		          </div>
      		        </div>
                  <div className="col-4">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Comuna</label>
      		            <div className="input-group">
      		                <Field className={`form-control ${getInputClasses( "district" )}`} type ="text" name="company.district" />
      		            </div>
      		          </div>
      		        </div>



                  {/* UPLOADS */}
                  <div className="col-6">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Certificado Digital</label>
      		            <div className="input-group">
                        <input
                          type="file"
                          id="digital_certificate"
                          name="digital_certificate"
                          className={getInputClasses( "digital_certificate" )}
                          onChange={(e) => {
                            handleSetCertificado(e)
                            formik.handleChange(e)
                          }}
                        />
      		            </div>
      		          </div>
      		        </div>
                  <div className="col-6">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Logo</label>
      		            <div className="brand-link">
                        <img src={srcLogo} className="brand-image img-circle elevation-1" style={{opacity: '.8'}} />
      		            </div>
      		            <div className="input-group mt-4">
                        <input
                          type="file"
                          id="logo"
                          name="logo"
                          className={getInputClasses( "logo" )}
                          onChange={(e) => {
                            handleSetLogo(e)
                            formik.handleChange(e)
                          }}
                        />
      		            </div>
      		          </div>
      		        </div>
                  {/* Fin Uploads */}
      		        <div className="col-4 offset-4">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Template de PDF</label>
      		            <div className="input-group">

													 <Field as="select" name="templatePdf_id">
													 		{
																templatesPdf.map( (template) => {
																	return <option key={template.id} value={template.id}> {template.name} </option>
																})
															}
														</Field>
      		            </div>
                      <a href="#" onClick={() => window.open(GetPdfExample(formik.values.templatePdf_id), "_blank")}> Ver ejemplo </a>
      		          </div>
      		        </div>
      		      </div>
      		      <div className="row">
      		        <div className="col-12">
      		          <Button
      		            id="generaNC"
      		            className="btn btn-success btn-block"
      		            type="submit"
      		            disabled={formik.isSubmitting}
      		          >
      		            {loading && <Spinner/>}
      		            Generar
      		          </Button>
      		        </div>
      		      </div>
      		    </div>
      		  </form>
      		</div>
		</FormikProvider>
    </>
  );
}
