import * as Yup from "yup";

export const Schema = Yup.object().shape({
  company:
      Yup.object().shape({
        ecoact: Yup.string().required(),
        resolution_date: Yup.string().min(1, 'Debe ser mayor a 0').required(),
        resolution_number: Yup.string().min(1, 'Debe ser mayor a 0').required(),
        address: Yup.string().min(0, 'Debe ser igual o mayor a 0').required(),
        business_activity: Yup.string().min(0, 'Debe ser igual o mayor a 0').required(),
        certificate_pass: Yup.string().min(0, 'Debe ser igual o mayor a 0').required(),
        district: Yup.string().min(0, 'Debe ser igual o mayor a 0').required(),
      })
    .required(),
  templatePdf_id: Yup.number().positive().integer().required(),
  digital_certificate: Yup.mixed().required(),
  logo: Yup.mixed().required()
});

