import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { createTokenPassword, login, resetPassword, verifyToken } from '../../components/common/Crud/Auth';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
import { Spinner } from '../../helpers/Icon';
import Swal from 'sweetalert2'
import { useHistory } from "react-router-dom";

export const RequestPasswordPage = () => {
  
  const history = useHistory();

  const [checking, setChecking] = useState(true)
  const [message, setMessage] = useState(null)
  const [alertClass, setAlertClass] = useState('danger');
  const [state, setState] = useState(false)
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)


  const validarToken = async () => {
    setChecking(true)
    await verifyToken().then((r) => {
      if(r.data.success) {
        setState(r.data.success)
        setUser(r.data.message)
        setAlertClass('info')
        setMessage('Solicitud correcta ✅')
        setTimeout(() => {
          setMessage(null)
        }, 5000);
      }else{
        setAlertClass('danger')
        setState(r.data.success)
        setMessage(r.data.message)
        setTimeout(() => {
          setMessage(null)
          if(!state) {
            history.push("/login");
          }
        }, 5000);
      }
      setChecking(false)
    }).catch((e) => {
      console.log("Error validar token", e)
      setState(false)
      setChecking(false)
      setTimeout(() => {
        setMessage(null)
      }, 5000);
    })
  }


  const initialValues = {
    email: ''
  };

  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email('Debe ser un email valido')
      .required('El email es requerido')
  });


  const formik = useFormik({
    initialValues,
    validationSchema: EmailSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //enableLoading();
      setLoading(true)
      createTokenPassword(values)
        .then(({ data }) => {
          setLoading(false)
          Swal.fire({
            title: 'Solicitud exitosa',
            text: 'Revisa tu correo, el link debe este allí',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then((isConfirm) => {
            history.push("/login");
          })
          setSubmitting(false)
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false);
        });
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  useEffect(() => {
    return () => {
      //cleanup
    }
  }, [])

  return (
    <>
      <div className="login-page">
        <div className="login-box">
            <div className="login-logo">
              <b>Dte</b>
            </div>
            <div className="card">
              <div className="card-body">
                {
                  message !== null &&
                  <div className={`alert alert-${alertClass} alert-dismissible`}>
                      {message}
                  </div>
                }
                {
                  formik.status ? (
                    <div className={`alert alert-danger alert-dismissible`}>
                      {formik.status}
                    </div>
                  ) : null
                }
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className={`form-control ${getInputClasses( "email" )}`}
                        placeholder="email@email.com"
                        {...formik.getFieldProps("email")} 
                        />
                        <ErrorMessage name="email">
                          { msg => <div style={{ color: 'red' }}>{msg}</div> }
                        </ErrorMessage>
                    </div>
                    <div className="row float-right">
                      <div className="col-1">
                        <button
                          className="btn btn-success"
                          type="submit"
                          disabled={formik.isSubmitting}
                        >
                          {loading && <Spinner/>}
                          Enviar
                        </button>
                      </div>
                    </div>
                  </form>
                </FormikProvider>
              </div>
            </div>
        </div>
      </div>

    </>
  )
}
