import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {DashboardPage} from '../pages/Dashboard';
import {DtesEmitidos} from '../pages/DtesEmitidos';
import {DtesRecibidos} from '../pages/DtesRecibidos';
import {FoliosPage} from '../pages/FoliosPage';
import {ConfiguracionPage} from '../pages/Configuracion';
import {ConfiguracionEmailPage} from '../pages/ConfiguracionEmailPage';
import {SentDte} from '../pages/SentDtePage';
import {UsuariosPage} from '../pages/Usuarios';

export const BasePage = props => {
  return (
    <Switch>
      <Route exact path="/">
        <DashboardPage setNamePage={props.setNamePage} />
      </Route>
      <Route exact path="/dtesEmitidos">
        <DtesEmitidos setNamePage={props.setNamePage} />
      </Route>
      <Route exact path="/dtesRecibidos">
        <DtesRecibidos setNamePage={props.setNamePage} />
      </Route>
      <Route exact path="/folios">
        <FoliosPage setNamePage={props.setNamePage} />
      </Route>
      <Route exact path="/configuracion">
        <ConfiguracionPage setNamePage={props.setNamePage} />
      </Route>
      <Route exact path="/configuracionEmail">
        <ConfiguracionEmailPage setNamePage={props.setNamePage} />
      </Route>
      <Route exact path="/usuarios">
        <UsuariosPage setNamePage={props.setNamePage} />
      </Route>

      <Route exact path="/consumidas/sentDte">
        <SentDte setNamePage={props.setNamePage} />
      </Route>
    </Switch>
  );
};
