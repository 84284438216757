import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import {MassiveDte, GetExample} from '../../components/common/Crud/Dtes';

export const ModalEmisionMasiva = (props) => {

  const [file, setFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertClass, setAlertClass] = useState('danger');

  const initialValues = {
    file: '',
  };

  const Schema = Yup.object().shape({
    file: Yup.mixed().required('El archivo caf es requerido')
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);
      if(file){
        const fileForm = new FormData();
        fileForm.append('file', file);
        MassiveDte(fileForm)
        .then(({ data }) => {
          if(data.success){
            setAlertClass('info');
            setStatus(data.message);
          }else{
            setAlertClass('danger');
            setStatus(data.message);
          }
        })
        .catch(() => {
          setAlertClass('danger');
          setStatus('Error al cargar el archivo.');
        });
      }else{
        setAlertClass('danger');
        setStatus('Error en el archivo');
      }
      setSubmitting(false);
      disableLoading();
    }
  });

  const handleSetFile = (e) =>{
    setFile(e.currentTarget.files[0]);
  }

  return (
    <div>
        <form onSubmit={formik.handleSubmit}>
        {
            formik.status ? (
                <div className={`alert alert-${alertClass} alert-dismissible`}>
                    {formik.status}
                </div>
            ) : null
        }
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <div className="input-group">
                    <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={(e) => {
                          handleSetFile(e)
                          formik.handleChange(e)
                        }}
                    />
                </div>
              </div>
            </div>
          </div>
          <div className="row p-5 justify-content-end">
            <div className="col-3">
              <button
                id="cargarFolio"
                className="btn btn-outline-primary"
                onClick={() => window.open(GetExample(), "_blank")}
              >
                Ejemplo
              </button>
            </div>
            <div className="col-1">
              <button
                id="cargarFolio"
                className="btn btn-success"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Cargar
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </div>
        </div>
        </form>
    </div>
  );

}
