import axios from "axios";

export const LOGIN_URL = "api/v2/login";
export const REGISTER_URL = "api/v2/register/";
export const REQUEST_PASSWORD_URL = "api/forgot-password/";
export const REFRESH_TOKEN_URL = '/api/login/refresh/';
export const VERIFY_TOKEN = 'api/v2/password/find/'
export const RESET_PASSWORD = 'api/v2/password/reset'
export const CREATE_TOKEN_PASSWORD = 'api/v2/password/create'

export const ME_URL = "api/users/me/";

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(values) {
  return axios.post(REGISTER_URL, values);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function verifyToken(token) {
  return axios.get(VERIFY_TOKEN+token);
}

export function resetPassword(data) {
  return axios.post(RESET_PASSWORD, {...data})
}

export function createTokenPassword(data) {
  return axios.post(CREATE_TOKEN_PASSWORD, {...data})
}
