import React, {useReducer, useEffect} from 'react';
import { BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import { LoginPage } from './pages/LoginPage';
import { RegisterPage } from './pages/RegisterPage';
import { Layout } from './components/common/Layout';
import {BasePage} from './app/BasePage';
import { LogoutPage } from './pages/LogoutPage';
import {AuthContext} from './helpers/auth/AuthContext';
import { authReducer } from './helpers/auth/authReducer'
import configAxios from './helpers/configAxios';
import {ResetPassword} from './pages/ResetPassword'
import axios from 'axios';
import { RequestPasswordPage } from './pages/ResetPassword/Request';

const App = (props) => {

  const init = () => {
    return typeof(localStorage.getItem('user')) == undefined || typeof(localStorage.getItem('user')) == 'object' ?
      { logged: false, company: undefined } :
      JSON.parse(localStorage.getItem('user'));
  }
  const [ user, dispatch ] = useReducer(authReducer, {}, init);

  useEffect(() => {
    localStorage.setItem( 'user', JSON.stringify(user) );
  }, [user])

  configAxios(axios, user);

  //let { token } = useParams();

  return (
    <AuthContext.Provider value={{ user, dispatch }}>
      <BrowserRouter>
        <Switch>
          {!user.logged ? (
            <Route exact path="/login">
              <LoginPage/>
            </Route>
          ) : (
            /*Otherwise redirect to root page (`/`)*/
            <Redirect from="/login" to="/" />
          )}

          <Route exact path="/logout">
            <LogoutPage/>
          </Route>

          <Route exact path="/register">
            <RegisterPage/>
          </Route>

          <Route exact path='/reset_password/:token' children={<ResetPassword/>} />
          
          <Route exact path="/request_password">
            <RequestPasswordPage/>
          </Route>

          {!user.logged ? (
            /*Redirect to `/auth` when user is not authorized*/
            <Redirect to="/login" />
          ) : (
            <Layout>
              <BasePage/>
            </Layout>
          )}


        </Switch>
      </BrowserRouter>
    </AuthContext.Provider>

  );
}

export default App;
