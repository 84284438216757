
export default function Icon({className}) {
    return (
            <i className={className} />
    )
}

export function Spinner() {
  return (
    <i className="fa fas fa-spinner fa-spin" />
  )
}
