import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { AuthContext } from '../helpers/auth/AuthContext';
import { types } from '../helpers/types';


export const LogoutPage = props => {
  const { dispatch } = useContext( AuthContext );

  dispatch({
    type: types.logout,
    payload: {}
  });

  return (
      <Redirect to="/" />
  )
}
