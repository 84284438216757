import axios from "axios";

// export const _COMPANY = sessionStorage.getItem('company');

export const EXAMPLEPDF = `/api/v2/getExamplePDF/`;
export const SETTING = `api/v2/companies/COMPANY/setting`;
export const COMPANY = `api/v2/companies/COMPANY/`;
export const COMPANY_CERTIFICATE = `api/v2/companies/COMPANY/certificate`;
export const COMPANY_LOGO = `api/v2/companies/COMPANY/logo`;
export const EMAIL_SETTING = `api/v2/companies/COMPANY/emailSettings`;
export const TEMPLATES_PDF = `api/v2/templatesPdf`;
export const EXCHANGE_EMAIL = `api/v2/companies/COMPANY/exchangeEmail`;

export function CompanySetting() {
  return axios.get(SETTING);
}

export function TemplatesPdf() {
  return axios.get(TEMPLATES_PDF);
}

export async function SetCompanySetting(companySetting) {
  return await axios.post(SETTING, {
    id: companySetting.id,
    templatePdf_id: companySetting.templatePdf_id
  });
}

export async function SetCompanyData(company) {
  return axios.put(COMPANY, {
    ecoact: company.ecoact,
    resolution_date: company.resolution_date,
    resolution_number: company.resolution_number,
    address: company.address,
    business_activity: company.business_activity,
    certificate_pass: company.certificate_pass,
    district: company.district,
  });
}

export async function setCertificateCompany(certificado) {
  const certificadoForm = new FormData();
  certificadoForm.append('certificado', certificado);
  return await axios.post(COMPANY_CERTIFICATE, certificadoForm , {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}

export async function setLogoCompany(logo) {
  return axios.post(COMPANY_LOGO, logo , {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}

export function EmailSetting() {
  return axios.get(EMAIL_SETTING);
}

export function SetEmailSetting(setting) {
  return axios.post(EMAIL_SETTING, setting);
}

export function ExchangeEmail() {
  return axios.get(EXCHANGE_EMAIL);
}

export function SetExchangeEmail(values) {
  return axios.post(EXCHANGE_EMAIL, values);
}

export function GetPdfExample (id) {
  return `${axios.defaults.baseURL}${EXAMPLEPDF}${id}`;
}
