import { Field, FieldArray, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import {CrearNotaCreditoDebito, CheckNotaCreditoDebito, TiposNotaCreditoDebito} from '../../components/common/Crud/Dtes';
import Table from 'react-bootstrap/Table'
import Icon, { Spinner } from '../../helpers/Icon'
// import Items from "../modal/components/notaCreditoDebito/Items";


export const ModalNotaCreditoDebito = ({notaCreditoDebito, handleNotaCreditoDebito}) => {

  const [loading, setLoading] = useState(false);
  const [exist, setExist] = useState(false);
  const [tiposReferencia, settiposReferencia] = useState([]);
  const [showItems, setshowItems] = useState({display: 'none'});
  const [showGiro, setShowGiro] = useState({display: 'none'})

  const [showItemsNew, setshowItemsNew] = useState(false);
  const [showGiroNew, setShowGiroNew] = useState(false)

  const [alertClass, setAlertClass] = useState('danger');

  const initialValues = {
    FolioRef: notaCreditoDebito.FolioRef,
    CodRef: notaCreditoDebito.CodRef,
    GiroRecep: notaCreditoDebito.GiroRecep,
    items: notaCreditoDebito.items
  };

  const Schema = Yup.object().shape({
    CodRef: Yup.number().positive().integer().required('El Codigo de Referencia es requerido'),
    FolioRef: Yup.string().required('El el folio de referencia es requerido'),
    GiroRecep: Yup.string().required('La razon de referencia es requerida'),
    items: Yup.array()
      .of(
        Yup.object().shape({
            name: Yup.string().required('Requerido'),
            quantity: Yup.string().min(1, 'Debe ser mayor a 0').required('Requerido'),
            price: Yup.string().min(1, 'Debe ser mayor a 0').required('Requerido'),
            discount: Yup.string().min(0, 'Debe ser igual o mayor a 0').required('Requerido'),
        })
      )
      .required('Debe tener al menos un item')
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const show = (val) => {
    //if(val === 2){
    //  setShowGiro({display: 'block'})
    //}else{
    //  setShowGiro({display: 'none'})
    //}
    //
    //if(val === 3){
    //  setshowItems({display: 'block'})
    //}else{
    //  setshowItems({display: 'none'})
    //}

    if(val == 2) {
      setShowGiroNew(true)
    }else{
      setShowGiroNew(false)
    }

    if(val == 3) {
      setshowItemsNew(true)
    }else{
      setshowItemsNew(false)
    }

  }

  const formik = useFormik({
    initialValues: initialValues,
    //validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
       if (notaCreditoDebito) {
         enableLoading();
         setSubmitting(true);
         handleNotaCreditoDebito(values);
         CrearNotaCreditoDebito({ ...notaCreditoDebito, ...values })
           .then(({ data }) => {
             if (data.success) {
               setAlertClass('info');
               setStatus(
                 `Documento creado correctamente`
               );
             } else {
               setAlertClass('danger');
               setStatus(data.message);
             }
           })
           .catch(() => {
             disableLoading();
             setSubmitting(false);
             setAlertClass('danger');
             setStatus('Error de comunicación.');
           });
         disableLoading();
         setSubmitting(false);
       } else {
         setAlertClass('danger');
         setStatus('Error en los datos del Documento.');
       }
    }
  });

  useEffect(() => {

    delete notaCreditoDebito.IVA
    delete notaCreditoDebito.MntNeto
    delete notaCreditoDebito.MntTotal



    // if(notaCreditoDebito.TipoDTE === 39){
    //   console.log("Es una boleta electronica")
    // }

    // if(notaCreditoDebito.TipoDTE === 61) {
    //   console.log("Es una nota de credito")
    // }

    if (notaCreditoDebito.TipoDTE == 39) {
      notaCreditoDebito.items.forEach((element) => {
        element.price = parseInt(element.price/parseFloat(`1.${notaCreditoDebito.TasaIVA}`))
      })
    }

    
    
    CheckNotaCreditoDebito(notaCreditoDebito.dte_type, notaCreditoDebito.FolioRef)
      .then(({data: {data}}) => {
        if(data){
          setExist(true);
        }
      })
      .catch(() => {
        setAlertClass('danger');
        formik.setStatus('Error de conexion con la API');
      });

    TiposNotaCreditoDebito(notaCreditoDebito.dte_type)
      .then( ({data}) => {
        settiposReferencia(data);
      })
      .catch(() => {
        setAlertClass('danger');
        formik.setStatus('Error de conexion con la API');
      });

  }, [])

  return (
    <>
      {
        !exist ? (
		      <FormikProvider value={formik}>
            <div>
              <form onSubmit={formik.handleSubmit}>
                {
                    formik.status ? (
                        <div className={`alert alert-${alertClass} alert-dismissible`}>
                            {formik.status}
                        </div>
                    ) : null
                }
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">Referencia a {notaCreditoDebito.tipo_dte} #{formik.values.FolioRef}</label>
                        <div className="input-group">
                            <input
                              style={{display:"none"}}
                              type='text'
                              {...formik.getFieldProps("FolioRef")}
                            />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">Razon Referencia</label>
                        <div className="input-group">
                            <Field as="select" name="CodRef"
                              onChange={(e) => {
                                show(e.target.selectedOptions[0].value);
                                formik.handleChange(e);
                            }}
                            >
					  									{
					  										tiposReferencia.map( (tipoReferencia) => {
					  											return <option key={tipoReferencia.codRef} value={tipoReferencia.codRef}> {tipoReferencia.RazonRef} </option>
					  										})
					  									}
					  								</Field>
                        </div>
                      </div>
                    </div>
                    {
                      showGiroNew &&
                      <div
                        className="col-12"
                        // style={showGiro}
                      >
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">Giro Receptor</label>
                          <div className="input-group col-12">
                            <Field name="GiroRecep">
                              {({field, form, meta}) =>
                                <input {...field} className="col-12" type="text" />
                              }
                            </Field>
                          </div>
                        </div>
                      </div>
                    }
                    {
                      showItemsNew &&
                      <div className="col-12"
                        // style={showItems}
                      >
                        <div className="form-group">
                          <label htmlFor="exampleInputFile">Items</label>
                          <div className="input-group col-12">
                            <Table borderless responsive size="sm">
                              <thead>
                                <tr>
                                  <th>Nombre</th>
                                  <th>Cantidad</th>
                                  <th>Precio</th>
                                  <th>Descuento $</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                <FieldArray name="items">
                                  {
                                    ({ remove }) => (
                                      <>
                                        {
                                          formik.values.items.length > 0 &&
                                          formik.values.items.map((item, index) => (
                                            <tr key={index}>
                                              <td>
                                                <Field
                                                  name={`items.${index}.name`}
                                                  type="text"
                                                />
                                              </td>
                                              <td>
                                                <Field
                                                  name={`items.${index}.quantity`}
                                                  type="number"
                                                />
                                              </td>
                                              <td>
                                                <Field
                                                  name={`items.${index}.price`}
                                                  type="number"
                                                />
                                              </td>
                                              <td>
                                                <Field
                                                  name={`items.${index}.discount`}
                                                  type="number"
                                                />
                                              </td>
                                              <td>
                                                <button
                                                  type="button"
                                                  className="btn btn-block btn-outline-danger btn-sm"
                                                  onClick={() => remove(index)}
                                                >
                                                  <Icon className="fa fas fa-trash" />
                                                </button>
                                              </td>
                                            </tr>
                                          ))
                                        }
                                      </>
                                    )
                                  }
                                </FieldArray>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    }
                    {/* <Items formik={formik}></Items> */}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        id="generaNC"
                        className="btn btn-success btn-block"
                        type="submit"
                        disabled={formik.isSubmitting}
                      >
                        {loading && <Spinner/>}
                        Generar
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </FormikProvider>
        ) : (
          <div className="alert alert-info alert-dismissible">
              Este Documento ya cuenta con una Nota de Crédito emitida.
          </div>
        )
      }
    </>
  );
}
