export default function ( axios, user ){


  axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
  axios.interceptors.request.use(
    config => {
      const accessToken = user.accessToken ?? null;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      config.url = config.url.replace('COMPANY', user.company);
      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      let data = response.data;
      if(typeof data.sucess != undefined){
        if(data.sucess === false){
          if(typeof data.message === 'object'){
            const map = new Map(Object.entries(data.message));
            let errores = '';

            map.forEach(val => {
              errores += '- ' + val + '\n'
            });

            data.message = errores;
          }
        }
      }

      response.data = data;
      return response;
    }
  );

}
