import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../helpers/auth/AuthContext';
import { Menu } from './Menu';

export const Header = () => {

  const { user } = useContext(AuthContext);

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
          </li>
        </ul>
      </nav>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <Link to='/' className="brand-link">
          <img src={user.logo ?? "dist/img/AdminLTELogo.png"} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity: '.8'}} />
          <span className="brand-text font-weight-light">DTE v2.0</span>
        </Link>
        <div className="sidebar">
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
            <img src={`https://ui-avatars.com/api/?name=${user.username}`} className="img-circle elevation-2" alt="User Image" />
              {/* <img src="dist/img/user.png" className="img-circle elevation-2" alt="User Image" /> */}
            </div>
            <div className="info">
              <a href="#" className="d-block">{user.username}</a>
            </div>
          </div>
            <Menu />
        </div>
      </aside>
    </>
  );
}
