import axios from "axios";

export const COMPANY = () => {
  if(localStorage.getItem('user') === undefined){
    return undefined;
  }
  return JSON.parse(localStorage.getItem('user')).company;
}

export const CONSUMIDAS = `api/v2/companies/COMPANY/dtes/sent`;
export const DISPATCH_GUIDES = `api/v2/companies/COMPANY/dtes/sent/dispatch_guide/0`;
export const RECIBIDOS = `api/v2/companies/COMPANY/dtes/received`;
export const SENT_DTE = `api/v2/companies/COMPANY/dtes/sent`;
export const SENT_NOTA_CREDITO = `api/v2/companies/COMPANY/create_note`;
export const CHECK_NOTA_CREDITO = `api/v2/companies/COMPANY/verify_note`;
export const TIPOS_NOTA_CREDITO_DEBITO = `api/v2/sub_types_dte/`;
export const TIPOS_DTES = `api/v2/types_dte/`;
export const EMISION_MASIVA = `api/v2/companies/COMPANY/dtes/sent/massive`

export async function DtesConsumidos(params) {
  return await axios.get(CONSUMIDAS, {params: {...params}});
}

export async function DtesRecibidos(params) {
  return await axios.get(RECIBIDOS, {params: {...params}});
}

export async function AceptacionRechazo(id, acepta) {
  return await axios.post(`${RECIBIDOS}/${id}/acceptance_receipt`, {acepta: acepta});
}

export async function AceptacionRechazoMasivo(ids, acepta) {
  return await axios.post(`${RECIBIDOS}/acceptance_receipt_massive`, {acepta: acepta, ids: ids});
}

export function SentDTE(dte) {
  return axios.post(SENT_DTE, dte);
}

export function CrearNotaCreditoDebito(notaCredito) {
  return axios.post(SENT_NOTA_CREDITO, notaCredito);
}

export function CheckNotaCreditoDebito(dte_type, folio) {
  return axios.post(CHECK_NOTA_CREDITO, {
    dte_type: dte_type,
    folio: folio
  });
}

export function TiposNotaCreditoDebito(dte_type) {
  return axios.get(`${TIPOS_NOTA_CREDITO_DEBITO}${dte_type}`);
}

export function TiposDtes() {
  return axios.get(TIPOS_DTES);
}


export function GetPdfVenta (id) {
  return `${axios.defaults.baseURL}/api/companies/${COMPANY()}/dtes/sent/${id}/getPDF`;
}

export function GetPdfCompra (id) {
  return `${axios.defaults.baseURL}/api/companies/${COMPANY()}/dtes/received/${id}/getPDF`;
}

export async function MassiveDte(form) {
  return await axios.post(EMISION_MASIVA, form , {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}

export function GetGuiasDespacho() {
  return axios.get(DISPATCH_GUIDES);
}

export async function FacturarGuiasDespacho(ids) {
  return await axios.post(`${CONSUMIDAS}/dispatch_guides`, {ids: ids});
}

export function GetExample (id) {
  return `${axios.defaults.baseURL}/api/v2/examples/facturacion_masiva`;
}
