import React from 'react'
//import { Button } from "react-bootstrap";
import Modal from 'react-modal';


export default (props) => {
  
  //console.log("Props Modal", props)

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
        isOpen={props.show}
        onRequestClose={() => console.log("Close Modal")}
        style={customStyles}
        contentLabel="Cargando"
        ariaHideApp={false}
      >      
        <div className="col-12">
          <div className="spinner-border" role="status">
          </div>
        </div>
    </Modal>
  );

}
