import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { login } from '../../Crud/Auth';
import { Spinner } from '../../../../helpers/Icon';
import { AuthContext } from '../../../../helpers/auth/AuthContext';
import { types } from '../../../../helpers/types';

export const Body = props => {

  const [loading, setLoading] = useState(false);
  const [alertClass, setAlertClass] = useState('danger');
  const { dispatch } = useContext( AuthContext );

  const initialValues = {
    email: "root@root.com",
    password: "password",
  };

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required('La contraseña es requerida'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      login(values.email, values.password)
        .then(({ data }) => {
          const access = {
            accessToken : data.data.accessToken,
            company : data.data.company,
            username: data.data.username,
            logo: data.data.logo,
            role: data.data.role,
            id: data.data.id
          };
          disableLoading();
          dispatch({
            type: types.login,
            payload: access
          });
          props.history.replace('/');
        })
        .catch(() => {
          disableLoading();
          setSubmitting(false);
          setStatus('Error al iniciar sesión');
        });
    },
  });

  return (
    <>
      <div className="login-box">
          <div className="login-logo">
            <b>Dte</b>
          </div>
          {/* /.login-logo */}
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Inicio de Sesión</p>
              <form onSubmit={formik.handleSubmit}>
                {
                  formik.status ? (
                    <div className={`alert alert-${alertClass} alert-dismissible`}>
                      {formik.status}
                    </div>
                  ) : null
                }
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className={`form-control ${getInputClasses( "email" )}`}
                    placeholder="Email"
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    className={`form-control ${getInputClasses( "password" )}`}
                    type="password"
                    placeholder="Password"
                    {...formik.getFieldProps("password")}
                    />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={formik.isSubmitting}
                      className={`btn btn-primary btn-block`}
                    >
                      {loading && <Spinner/>}
                      <span>Log in</span>
                    </button>
                  </div>
                </div>
              </form>
              <p className="mb-0 p-2">
                <a href="/request_password" className="text-center">Recuperar contraseña</a>
              </p>
            </div>
          </div>
      </div>
    </>
  )
}
