import React, { useEffect, useState, useContext } from 'react';
import {DtesConsumidos, GetPdfVenta} from '../../components/common/Crud/Dtes';
import DataTable from 'react-data-table-component';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import { ModalNotaCreditoDebito } from './NotaCreditoDebito';
import { ModalEmisionMasiva } from './EmisionMasiva';
import { ModalFacturacionGuiasDespacho } from './FacturacionGuiasDespacho';
import ModalLayaut from '../modal/ModalLayaut';
import Icon from '../../helpers/Icon'
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ModalLoading from '../modal/ModalLoading';
import { AuthContext } from '../../helpers/auth/AuthContext';

export const DtesEmitidos = props => {
    const [modalShow, setModalShow] = useState(false);
    const [modalEmisionShow, setModalEmisionShow] = useState(false)
    const [modalFacturaGuiasShow, setModalFacturaGuiasShow] = useState(false)
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const [status, setStatus] = useState(false);
    const [alertClass, setAlertClass] = useState('info');

    const [loading, setLoading] = useState(false);
    
    const notaCredito = {dte_type: 61};
    const notaDebito = {dte_type: 56};

    const { user } = useContext(AuthContext);


    const [notaCreditoDebito, setNotaCreditoDebito] = useState({
      dte_type: null,
      items: {},
      send_mail: 1,
      email_recept: '',
      TpoDocRef: 0,
      MntTotal: 0,
      FolioRef: '',
      CodRef: 1,
      TasaIVA: 19,
      RazonRef: 1,
      ref_id: uuidv4()
    });

	  const [totalRows, setTotalRows] = useState(0);
	  const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [folio, setFolio] = useState('');
    const [rut_recept, setRut_recept] = useState();
    const [fecha, setFecha] = useState('');

    const paginationComponentOptions = {
      rowsPerPageText: 'Filas por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',
    };


    const handleFilters = async () => {
      setLoading(true)
      await DtesConsumidos({page: page, per_page: perPage, folio, rut_recept, fecha})
        .then((data) => {
          setData(data.data.data);
          setPage(data.data.pagination.current_page)
          setPerPage(data.data.pagination.per_page)
          setTotalRows(data.data.pagination.total_rows)
          setLoading(false)
        })
        .catch(() => {
          setAlertClass('danger');
          setStatus('Error de conexion con la API');
          setLoading(false)
        });
        setLoading(false)
    }

    const handlePageChange = async (page) => {
      setLoading(true)
      await DtesConsumidos({page: page, per_page: perPage})
        .then((data) => {
          setData(data.data.data);
          setPage(data.data.pagination.current_page)
          setPerPage(data.data.pagination.per_page)
          setLoading(false)
        })
        .catch(() => {
          setAlertClass('danger');
          setStatus('Error de conexion con la API');
          setLoading(false)
        });
        setLoading(false)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
      setLoading(true)
      await DtesConsumidos({page: page, per_page: newPerPage})
        .then((data) => {
          setData(data.data.data);
          setPage(data.data.pagination.current_page)
          setPerPage(data.data.pagination.per_page)
          setTotalRows(data.data.pagination.total_rows)
          setLoading(false)
        })
        .catch(() => {
          setAlertClass('danger');
          setStatus('Error de conexion con la API');
          setLoading(false)
        });
        setLoading(false)
    };

    const handleNotaCreditoDebito = (nota) => {
      setNotaCreditoDebito({...notaCreditoDebito, ...nota})
    }

    const inArray = (needle, haystack) => {
      var length = haystack.length;
      for(var i = 0; i < length; i++) {
          if(haystack[i] == needle) return true;
      }
      return false;
    }

    const columns = [
      {
        name: 'Folio',
        selector: 'FolioRef',
        sortable: true,
      },
      {
        name: 'Track id',
        selector: 'trackid',
        sortable: true,
      },
      {
        name: 'Tipo Doc.',
        selector: 'tipo_dte',
        sortable: true,
      },
      {
        name: 'Nombre',
        selector: 'RznSocRecep',
        sortable: true,
      },
      {
        name: 'Rut',
        selector: 'RUTRecep',
        sortable: true,
      },
      {
        name: 'Neto',
        selector: 'MntNeto',
        sortable: true,
      },
      {
        name: 'IVA',
        selector: 'IVA',
        sortable: true,
      },
      {
        name: 'Total',
        selector: 'MntTotal',
        sortable: true,
      },
      {
        name: 'Fecha',
        selector: 'date',
        sortable: true,
      },
      {
        name: 'Estado',
        selector: 'status_code',
        sortable: true,
        cell: row => <> {
            row.status_code ?
              (inArray(row.status_code, ['RCH', 'Envio Rech', 'RSC', 'RCO', 'RFR', 'RPT', 'RCT', 'FAU']) ?
                <span className="right badge badge-danger" title={row.status_description}>{row.status_code}</span> :
                <span className="right badge badge-info" title={row.status_description}>{row.status_code}</span>
              ) :
              <span className="right badge badge-warning" title="No se ha podido obtener el estado">Pendiente</span>
          } </>,
      },
      {
        cell: row => {
          return (
            <div>
              <DropdownButton id="dropdown-basic-button" title="">
                <Dropdown.Item
                  onClick={() => window.open(GetPdfVenta(row.id), "_blank")}
                >
                  PDF
                </Dropdown.Item>
                {
                  (row.TipoDTE !== 61) ? (
                    <>
                      <Dropdown.Divider/>
                      <Dropdown.Item
                        onClick={ () => {
                          setNotaCreditoDebito({...notaCreditoDebito, ...row, ...notaCredito});
                          setTitleModal('Generar Nota de Crédito')
                          setModalShow(true);
                        }}
                      >
                        Nota Crédito
                      </Dropdown.Item>
                    </>
                  ) : null
                }
                {
                  (row.TipoDTE !== 56) ? (
                    <>
                      <Dropdown.Divider/>
                      <Dropdown.Item
                        onClick={ () => {
                          setNotaCreditoDebito({...notaCreditoDebito, ...row, ...notaDebito});
                          setTitleModal('Generar Nota de Débito')
                          setModalShow(true);
                        }}
                      >
                        Nota Débito
                      </Dropdown.Item>
                    </>
                  ) : null
                }

              </DropdownButton>
            </div>
          );
        },
        name: 'Acciones',
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
    ];

    const getDtes = async () => {
      setLoading(true)
      await DtesConsumidos({page: page, per_page: perPage})
        .then((data) => {
          setData(data.data.data);
          setPerPage(data.data.pagination.per_page)
          setTotalRows(data.data.pagination.total_rows)
          setLoading(false)
        })
        .catch(() => {
          setAlertClass('danger');
          setStatus('Error de conexion con la API');
          setLoading(false)
        });
        setLoading(false)
    }

    useEffect(() => {
      getDtes();
    }, [modalShow, modalEmisionShow])

    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
    					    <div className="col-11">
                    <h3 className="card-title">Documentos Enviados</h3>
    					    </div>
                  <div className="col-1 float-right">
                      <DropdownButton id="dropdown-basic-button" title="Acciones" menuAlign="right">
                    <>
                      <Dropdown.Divider/>
                      <Dropdown.Item
                        href="consumidas/sentDte"
                      >
                        <p><Icon className="fa fas fa-plus" /> Emitir</p>
                      </Dropdown.Item>
                      <Dropdown.Divider/>
                      <Dropdown.Item
                        onClick={ () => {
                          setModalEmisionShow(true);
                        }}
                      >
                        <p><Icon className="fa fas fa-plus" /> Emitir masivamente</p>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={ () => {
                          setModalFacturaGuiasShow(true);
                        }}
                      >
                        <p><Icon className="fa fas fa-plus" /> Facturar Guias Despacho</p>
                      </Dropdown.Item>
                    </>
                  </DropdownButton>
    					    </div>
                </div>

                <div className="card-body">
                  {
                    status ? (
                        <div className={`alert alert-${alertClass} alert-dismissible`}>
                            {status}
                        </div>
                    ) : null
                  }
                  <div className='row d-flex align-items-end'>
                    <div className="form-group col-auto">
                      <label>Folio</label>
                      <input type="number" value={folio} onChange={(e) => setFolio(e.target.value)} className="form-control" placeholder="12345"/>
                    </div>
                    <div className="form-group col-auto">
                      <label>Rut Receptor</label>
                      <input type="text" value={rut_recept} onChange={(e) => setRut_recept(e.target.value)} className="form-control" placeholder="11111111-1"/>
                    </div>
                    <div className="form-group col-auto">
                      <label>Fecha</label>
                      <input type="date" className="form-control" value={fecha} onChange={(e) => setFecha(e.target.value)} placeholder="2021-10-26"/>
                    </div>
                    <div className="form-group col-auto">
                    <button type="button" onClick={handleFilters} className="btn btn-block btn-primary">Buscar</button>
                    </div>
                  </div>
                  <DataTable
                    columns={columns}
                    data={data}
                    noDataComponent="No existen datos para mostrar"
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    paginationServer
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationTotalRows={totalRows}
                    selectableRows={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLayaut size="xl" title={titleModal} show={modalShow} onHide={() => setModalShow(false)}>
          <ModalNotaCreditoDebito notaCreditoDebito={notaCreditoDebito} handleNotaCreditoDebito={handleNotaCreditoDebito}/>
        </ModalLayaut>

        <ModalLayaut size="xs" title="Emisión Masiva de Documentos" show={modalEmisionShow} onHide={() => setModalEmisionShow(false)}>
          <ModalEmisionMasiva />
        </ModalLayaut>

        <ModalLayaut size="xl" title="Facturación Guias de Despacho" show={modalFacturaGuiasShow} onHide={() => setModalFacturaGuiasShow(false)}>
          <ModalFacturacionGuiasDespacho />
        </ModalLayaut>

        <ModalLoading show={loading} onHide={() => setLoading(false)} />
      </>
    )
}
