import React, { useContext, useState } from 'react';
import {Redirect} from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { register } from '../../Crud/Auth';
import { Spinner } from '../../../../helpers/Icon';
import { AuthContext } from '../../../../helpers/auth/AuthContext';
import { types } from '../../../../helpers/types';
import { rut } from '../../../../helpers/rut';

export const Body = props => {
  const { user, dispatch } = useContext( AuthContext );

  const [loading, setLoading] = useState(false);
  const [alertClass, setAlertClass] = useState('danger');

  const initialValues = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    business_name: "",
    rut: "",
    address: "",
    district: "",
  };

  const LoginSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required('El Nombre es requerido'),
    email: Yup.string()
      .email(),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required('La contraseña es requerida'),
    password_confirmation: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Las contraseñas no coinciden."
        )
      }),
    business_name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required('La contraseña es requerida'),
    rut: Yup.string()
      .min(9, "Minimum 3 symbols")
      .max(10, "Maximum 50 symbols")
      .required('El correo es requerido'),
    address: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required('La dirección es requerida'),
    district: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required('La comuna es requerida'),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      register(values)
        .then(({ data }) => {
          if(data.success === true){
            const access = {
              accessToken : data.data.accessToken,
              company : data.data.company,
              username: data.data.username,
              role: data.data.role
            };
            disableLoading();
            dispatch({
              type: types.login,
              payload: access
            });
            props.history.replace('/');
          }else{
            setStatus(data.message);
            disableLoading();
            setSubmitting(false);
          }
        })
        .catch(() => {
          disableLoading();
          setSubmitting(false);
          setStatus('Error desconocido');
        });
    }
  });

  const validateRut = (e) => {
    e.currentTarget.className = 'form-control ' + rut(e.currentTarget.value)
  }

  if (user.logged) {
    return <Redirect to={`/`} />
  }

  return (
    <>
      <div className="login-box">
          <div className="login-logo">
            <b>Dte</b>
          </div>
          {/* /.login-logo */}
          <div className="card">
            <div className="card-body login-card-body">
              <p className="login-box-msg">Registro de Usuario</p>
              <form onSubmit={formik.handleSubmit}>
                {
                  formik.status ? (
                    <div className={`alert alert-${alertClass} alert-dismissible`}>
                      {formik.status}
                    </div>
                  ) : null
                }
                <div className="input-group mb-3">
                  <input
                    type="string"
                    className={`form-control ${getInputClasses( "name" )}`}
                    placeholder="Nombre Completo"
                    name="name"
                    {...formik.getFieldProps("name")}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-user" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className={`form-control ${getInputClasses( "email" )}`}
                    placeholder="Email"
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    className={`form-control ${getInputClasses( "password" )}`}
                    type="password"
                    placeholder="Password"
                    {...formik.getFieldProps("password")}
                    />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    className={`form-control ${getInputClasses( "password_confirmation" )}`}
                    type="password"
                    placeholder="Confirme Password"
                    {...formik.getFieldProps("password_confirmation")}
                    />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <p className="login-box-msg">Empresa</p>
                {/* DATOS EMPRESA */}
                <div className="input-group mb-3">
                  <input
                    className={`form-control ${getInputClasses( "business_name" )}`}
                    type="text"
                    placeholder="Nombre de la empresa"
                    {...formik.getFieldProps("business_name")}
                    />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    className={`form-control`}
                    type="text"
                    placeholder="Rut"
                    {...formik.getFieldProps("rut")}
                    onChange={(e) => {
                      validateRut(e)
                      formik.handleChange(e)
                    }}
                    />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    className={`form-control ${getInputClasses( "address" )}`}
                    type="text"
                    placeholder="Direccion"
                    {...formik.getFieldProps("address")}
                    />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    className={`form-control ${getInputClasses( "district" )}`}
                    type="text"
                    placeholder="Comuna"
                    {...formik.getFieldProps("district")}
                    />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                {/* FIN DATOS EMPRESA */}
                <div className="row">
                  <div className="col">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={formik.isSubmitting}
                      className={`btn btn-primary btn-block`}
                    >
                      {loading && <Spinner/>}
                      <span>Registrarse</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
      </div>
    </>
  )
}
