import React, { useEffect, useState } from 'react';
import { Field, useFormik, FormikProvider } from 'formik';
import * as Yup from "yup";
import { Register, GetTypesUser } from '../../components/common/Crud/Users';
import { Spinner } from '../../helpers/Icon';

export const ModalSetUsuario = props => {

  const [loading, setLoading] = useState(false);
  const [tiposUsuario, setTiposUsuario] = useState([])
  const [alertClass, setAlertClass] = useState('danger');

  const initialValues = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    userType: 'admin'
  };

  const LoginSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required('El Nombre es requerido'),
    email: Yup.string()
      .email(),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required('La contraseña es requerida'),
    password_confirmation: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Las contraseñas no coinciden."
        )
      }),
    userType: Yup.string().required(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);
      Register(values)
      .then(({ data }) => {
        if(data.success == true){
          setAlertClass('info');
          setStatus("Usuario creado correctamente");
        }else{
          setAlertClass('danger');
          setStatus(data.message);
        }
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error al crear usuario');
      });
      disableLoading();
      setSubmitting(false);

    }
  });

  useEffect(() => {
    GetTypesUser()
      .then( ({data}) => {
        setTiposUsuario(data);
      })
      .catch(() => {
        setAlertClass('danger');
        formik.setStatus('Error de conexion con la API');
      });
  }, [])

  return (
      <FormikProvider value={formik}>
        <div>
            <div className="card">
              <div className="card-body login-card-body">
                <p className="login-box-msg">Registro de Usuario</p>
                <form onSubmit={formik.handleSubmit}>
                  {
                    formik.status ? (
                      <div className={`alert alert-${alertClass} alert-dismissible`}>
                        {formik.status}
                      </div>
                    ) : null
                  }
                  <div className="input-group mb-3">
                    <input
                      type="string"
                      className={`form-control ${getInputClasses( "name" )}`}
                      placeholder="Nombre Completo"
                      name="name"
                      {...formik.getFieldProps("name")}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-user" />
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      type="email"
                      className={`form-control ${getInputClasses( "email" )}`}
                      placeholder="Email"
                      name="email"
                      {...formik.getFieldProps("email")}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope" />
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      className={`form-control ${getInputClasses( "password" )}`}
                      type="password"
                      placeholder="Password"
                      {...formik.getFieldProps("password")}
                      />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div>
                  </div>
                  <div className="input-group mb-3">
                    <input
                      className={`form-control ${getInputClasses( "password_confirmation" )}`}
                      type="password"
                      placeholder="Confirme Password"
                      {...formik.getFieldProps("password_confirmation")}
                      />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock" />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Tipo de Usuario</label>
      		            <div className="input-group">
													 <Field as="select" name="userType">
													 		{
																tiposUsuario.map( (type) => {
																	return <option key={type.id} value={type.code}> {type.code} - {type.description} </option>
																})
															}
														</Field>
      		            </div>
      		          </div>
      		        </div>
                  <div className="row">
                    <div className="col">
                      <button
                        id="kt_login_signin_submit"
                        type="submit"
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary btn-block`}
                      >
                        {loading && <Spinner/>}
                        <span>Crear</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </FormikProvider>
  )
}
