import axios from "axios";

// export const COMPANY = sessionStorage.getItem('company');

export const CONSUMIDAS = `api/v2/companies/COMPANY/cafs`;
export const UPLOAD_CAF = `api/v2/companies/COMPANY/cafs`;

export async function fetchCafs(params) {
  return await axios.get(CONSUMIDAS, {params: {...params}});
}

export async function upload(form) {
  return await axios.post(UPLOAD_CAF, form , {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}
