import React, { useEffect, useState } from 'react';
import {SetEmailSetting , EmailSetting} from '../components/common/Crud/Companies';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from "yup";
import { Spinner } from '../helpers/Icon';


export const ConfiguracionEmailPage = props => {

  const [loading, setLoading] = useState(false);
  const [emailSetting, setEmailSetting] = useState(false);
  const [alertClass, setAlertClass] = useState('danger');
  const [initialValues, setInitialValues] = useState({
		subject: '',
		copy_mail: '',
		from_mail: ''
	});

  const getEmailSetting = () =>{
    EmailSetting()
			.then( ({data}) => {
				setEmailSetting(data);
				formik.setFieldValue('subject', data.subject);
				formik.setFieldValue('copy_mail', data.copy_mail);
				formik.setFieldValue('from_mail', data.from_mail);
			})
			.catch(() => {
        setAlertClass('danger');
        formik.setStatus('Error de conexion con la API');
      });
  }

	useEffect(() => {
		getEmailSetting()
	}, [])

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

	const Schema = Yup.object().shape({
    subject: Yup.string().required(),
    copy_mail: Yup.string().required(),
    from_mail: Yup.string().required(),
  });

	const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);
      SetEmailSetting(values)
        .then( ({data}) => {
          if(data.success){
            setAlertClass('info');
            setStatus('Configuración actualizada correctamete.');
          }else{
            setAlertClass('danger');
            setStatus('Error al actualizar configuracion.');
          }
        })
        .catch(() => {
          setAlertClass('danger');
          setStatus('Error al actualizar configuracion.');

        });
      setSubmitting(false);
      disableLoading()
    }
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

	return (
		<FormikProvider value={formik}>
	  	<div className="container-fluid">
				<div className="row ">
			  	<div className="col-12">
						<div className="card">
				  		<div className="card-header container-fluid">
					  		<div className="row">
    					    	<div className="col-11">
    					        	<h3 className="w-75 p-3">Configuración del Email</h3>
    					    	</div>
    						</div>
				  		</div>
				  		<div className="card-body">
								<div className="row justify-content-center">
                  <form onSubmit={formik.handleSubmit}>
									  <div className="col-6">
                      {
      		                formik.status ? (
      		                    <div className={`alert alert-${alertClass} alert-dismissible`}>
      		                        {formik.status}
      		                    </div>
      		                ) : null
      		            }
									  	<div className="col-12">
      	        	  	  <div className="form-group">
      	        	  	    <label htmlFor="exampleInputFile">Asunto </label>
      	        	  	    <div className="input-group">
      	        	  	      <Field className={`form-control col-12 ${getInputClasses( "subject" )}`} type="text" name="subject" />
      	        	  	    <label><small>{"Para agregar la Razon Social escribir {empresa} y para el Folio {folio}"}</small></label>
      	        	  	    </div>
      	        	  	  </div>
      	        	  	</div>
									  	<div className="col-12">
      	        	  	  <div className="form-group">
      	        	  	    <label htmlFor="exampleInputFile">CC</label>
      	        	  	    <div className="input-group">
      	        	  	      <Field className={`form-control col-12 ${getInputClasses( "copy_mail" )}`} type="text" name="copy_mail" />
      	        	  	    </div>
      	        	  	  </div>
      	        	  	</div>
									  	<div className="col-12">
      	        	  	  <div className="form-group">
      	        	  	    <label htmlFor="exampleInputFile">Desde</label>
      	        	  	    <div className="input-group">
      	        	  	      <Field className={`form-control col-12 ${getInputClasses( "from_mail" )}`} type="string" name="from_mail" />
      	        	  	    </div>
      	        	  	  </div>
      	        	  	</div>
									  	<div className="col-12">
      	        	  	  <div className="form-group">
      	        	  	    	<button className="btn btn-success btn-block" type="submit"> {loading && <Spinner/>} Guardar </button>
      	        	  	  </div>
      	        	  	</div>
      	        	  </div>
                  </form>
				  			</div>
				  		</div>
						</div>
			  	</div>
				</div>
	  	</div>
		</FormikProvider>
	)
}
