import React from 'react';

export const Body = (props) => {

    return (
        <div className="content-wrapper">
          {/* header content */}
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                {/* breadcrumb */}
                {/* <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">Dashboard v1</li>
                  </ol>
                </div> */}
                {/* end breadcrumb */}
              </div>
            </div>
          </div>
          <section  className="content" {...props}>
              {props.children}
          </section>
        </div>

    );
}
