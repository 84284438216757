import React, { useEffect, useState } from 'react';
import {fetchCafs} from '../components/common/Crud/Cafs';
import DataTable from 'react-data-table-component';
import { Button} from 'react-bootstrap';
import ModalLayaut from './modal/ModalLayaut'
import ModalFolios from './modal/Folios'
import ModalLoading from './modal/ModalLoading';

export const FoliosPage = props => {
	const [modalShow, setModalShow] = useState(false);
	const [data, setData] = useState([]);
  	const [status, setStatus] = useState(false);
  	const [alertClass, setAlertClass] = useState('info');
	const [loading, setLoading] = useState(false);

	const [totalRows, setTotalRows] = useState(100);
  	const [perPage, setPerPage] = useState(10);
  	const [page, setPage] = useState(1);

  	const [folio, setFolio] = useState('');
  	const [fecha, setFecha] = useState('');

  	const paginationComponentOptions = {
  	  rowsPerPageText: 'Filas por página',
  	  rangeSeparatorText: 'de',
  	  selectAllRowsItem: true,
  	  selectAllRowsItemText: 'Todos',
  	};

	const handlePageChange = page => {
		setLoading(true)
		fetchCafs({ page: page, per_page: perPage })
			.then(({data}) => {
				const folios = data.data.map(folio => {
					return {
					  id: folio.id,
					  start: folio.range1,
					  end: folio.range2,
					  folio: folio.folio,
					  dte_type: folio.tipo,
					  date: folio.fecha,
					};
				});
			
				setData(folios);
				setPerPage(data.pagination.per_page)
				setPage(data.pagination.current_page)
				setTotalRows(data.pagination.total_rows)
				setLoading(false)
			})
			.catch(() => {
				setAlertClass('danger');
				setStatus('Error de conexion con la API');
				setLoading(false)
			});
	};

	const handleFilters = () => {
		setLoading(true)
		fetchCafs({ page: page, per_page: perPage, folio, fecha })
			.then(({data}) => {
				const folios = data.data.map(folio => {
					return {
					  id: folio.id,
					  start: folio.range1,
					  end: folio.range2,
					  folio: folio.folio,
					  dte_type: folio.tipo,
					  date: folio.fecha,
					};
				});
			
				setData(folios);
				setPerPage(data.pagination.per_page)
				setPage(data.pagination.current_page)
				setTotalRows(data.pagination.total_rows)
				setLoading(false)
			})
			.catch(() => {
				setAlertClass('danger');
				setStatus('Error de conexion con la API');
				setLoading(false)
			});
	}

	const handlePerRowsChange = async (newPerPage, page) => {
		setLoading(true)
		fetchCafs({ page: page, per_page: newPerPage })
			.then(({data}) => {
				const folios = data.data.map(folio => {
					return {
					  id: folio.id,
					  start: folio.range1,
					  end: folio.range2,
					  folio: folio.folio,
					  dte_type: folio.tipo,
					  date: folio.fecha,
					};
				});
			
				setData(folios);
				setPerPage(data.pagination.per_page)
				setPage(data.pagination.current_page)
				setTotalRows(data.pagination.total_rows)
				setLoading(false)
			})
			.catch(() => {
				setAlertClass('danger');
				setStatus('Error de conexion con la API');
				setLoading(false)
			});
	};

	const columns = [
	  	{
			name: 'Inicio',
			selector: 'start',
			sortable: true,
	  	},
	  	{
			name: 'Fin',
			selector: 'end',
			sortable: true,
	  	},
	  	{
			name: 'Folio',
			selector: 'folio',
			sortable: true,
	  	},
	  	{
			name: 'Tipo Documento',
			selector: 'dte_type',
			sortable: true,
	  	},
	  	{
			name: 'Fecha',
			selector: 'date',
			sortable: true,
	  	},
	];


	useEffect(() => {
	  	getCafs();
	}, [modalShow])

	const getCafs = () => {
		setLoading(true)
		fetchCafs()
		.then(({data}) => {
		  const folios = data.data.map(folio => {
			return {
			  id: folio.id,
			  start: folio.range1,
			  end: folio.range2,
			  folio: folio.folio,
			  dte_type: folio.tipo,
			  date: folio.fecha,
			};
		  });

		  setPage(data.pagination.current_page)
		  setTotalRows(data.pagination.total_rows)
		  //setTotalRows(100)
		  setData(folios);
		  setLoading(false)
		})
    	.catch(() => {
    	  setAlertClass('danger');
    	  setStatus('Error de conexion con la API');
		  setLoading(false)
    	});
	}

	return (
		<>
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-header container-fluid">
								<div className="row">
									<div className="col-11">
										<h3 className="w-75 p-3">Folios</h3>
									</div>
									<div className="col-1 float-right">
										<Button variant="primary" onClick={() => setModalShow(true)}>Cargar</Button>
									</div>
								</div>
							</div>
							<div className="card-body">
								{
									status ? (
										<div className={`alert alert-${alertClass} alert-dismissible`}>
											{status}
										</div>
									) : null
								}
								<div className='row d-flex align-items-end'>
									<div className="form-group col-auto">
										<label>Folio</label>
										<input type="number" value={folio} onChange={(e) => setFolio(e.target.value)} className="form-control" placeholder="12345" />
									</div>
									<div className="form-group col-auto">
										<label>Fecha</label>
										<input type="text" value={fecha} onChange={(e) => setFecha(e.target.value)} className="form-control" placeholder="2021-09-30" />
									</div>
									<div className="form-group col-auto">
										<button type="button" onClick={handleFilters} className="btn btn-block btn-primary">Buscar</button>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<DataTable
											columns={columns}
											data={data}
											noDataComponent="No existen datos para mostrar"
											pagination
                  							paginationComponentOptions={paginationComponentOptions}
                  							paginationServer
                  							onChangeRowsPerPage={handlePerRowsChange}
                  							onChangePage={handlePageChange}
                  							paginationTotalRows={totalRows}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalLayaut show={modalShow} onHide={() => setModalShow(false)}>
				<ModalFolios />
			</ModalLayaut>
			<ModalLoading show={loading} onHide={() => setLoading(false)} />
		</>
	)
}
