import React, { useEffect, useState } from 'react';
import {
  DtesRecibidos as CrudDtesRecibidos,
  AceptacionRechazo,
  AceptacionRechazoMasivo,
  GetPdfCompra
} from '../../components/common/Crud/Dtes';
import DataTable from 'react-data-table-component';
import {DropdownButton, Dropdown, Toast} from 'react-bootstrap';
import Icon from '../../helpers/Icon';
import Swal from 'sweetalert2'
import ModalLoading from '../modal/ModalLoading';

export const DtesRecibidos = props => {

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(true);
  const [rowSelected, setRowSelected] = useState(undefined);
  const [isSelected, setIsSelected] = useState(false);
  const [message, setmessage] = useState(undefined);
  const [status, setStatus] = useState(false);
  const [alertClass, setAlertClass] = useState('info');

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [folio, setFolio] = useState('');
  const [rut_emisor, setRut_emisor] = useState();
  const [fecha, setFecha] = useState('');

  const [loading, setLoading] = useState(false);

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  };

  const handlePageChange = async (page) => {
    setLoading(true)
    await CrudDtesRecibidos({page: page, per_page: perPage})
      .then((data) => {
        setData(data.data.data.dtes.data);
        setPerPage(data.data.data.dtes.per_page)
        setPage(data.data.data.dtes.current_page)
        setLoading(false)
        //setTotalRows(100)
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de conexion con la API');
        setLoading(false)
      });
      setLoading(false)
  };

  const handleFilters = async () => {
    setLoading(true)
    await CrudDtesRecibidos({page: page, per_page: perPage, folio, rut_emisor, fecha})
      .then((data) => {
        setData(data.data.data.dtes.data);
        setPage(data.data.data.dtes.current_page)
        setPerPage(data.data.data.dtes.per_page)
        setTotalRows(data.data.data.total_rows)
        setLoading(false)
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de conexion con la API');
        setLoading(false)
      });
    setLoading(false)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true)
    await CrudDtesRecibidos({page: page, per_page: newPerPage})
      .then((data) => {
        setData(data.data.data.dtes.data);
        setPage(data.data.data.dtes.current_page)
        setPerPage(data.data.data.dtes.per_page)
        setTotalRows(data.data.data.total_rows)
        setLoading(false)
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de conexion con la API');
        setLoading(false)
      });
  };

  const HandleClick = (acepta, row) =>  {
    const text = acepta ? 'Aceptar' : 'Rechazar';

    Swal.fire({
      title: 'Responder al Proveedor',
      type: 'question',
      text: `¿Desea ${text} el documento ${row.folio} emitido por ${row.razon_social}`,
      showCancelButton: true,
      confirmButtonText: 'Si!',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        let timerInterval;
        const processing = Swal.fire({
          title: 'Enviando respuesta',
          timer: 200000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            AceptacionRechazo(row.id, acepta)
              .then( ({data}) => {
                if(data.success){
                  Swal.close();
                  Swal.fire('Respuesta enviada con exito!', '', 'success')
                  setReload(true);
                }else{
                  Swal.close();
                  Swal.fire('Error al enviar respuesta!', '', 'danger')
                }
              })
              .catch(() => {
                setAlertClass('danger');
                setStatus('Error de conexion con la API');
              });
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer')
          }
        })
      }
    });
  }

  const columns = [
    {
      name: 'Folio',
      selector: 'folio',
      sortable: true,
    },
    {
      name: 'Tipo Doc.',
      selector: 'dte_type.type',
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: 'razon_social',
      sortable: true,
    },
    {
      name: 'Rut',
      selector: 'rut_emisor',
      sortable: true,
    },
    {
      name: 'Neto',
      selector: 'amount_net',
      sortable: true,
    },
    {
      name: 'IVA',
      selector: 'amount_iva',
      sortable: true,
    },
    {
      name: 'Total',
      selector: 'amount_total',
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: 'date',
      sortable: true,
    },
    {
      name: 'Estado',
      selector: 'status',
      sortable: true,
      cell: row => <> {
          typeof(row.answer) == 'object' ?
            (typeof(row.xml) == 'object' ?
              <span className="right badge badge-warning" title="No se ha recibido el XML en el correo de intercambio.">Sin XML</span> :
              <span className="right badge badge-danger" title="Aun no se ha generado respuesta">No Respondido</span> ) :
            ( row.answer == 'aceptado' ?
              <span className="right badge badge-success" title="Respuesta enviada al proveedor">Aceptado</span> :
              <span className="right badge badge-danger" title="Respuesta enviada al proveedor">Rechazado</span>
            )
        } </>,
    },
    {
      cell: row => {
        return (
          <div>
            <DropdownButton id="dropdown-basic-button" title="">
              <Dropdown.Item
                disabled={typeof(row.xml) == 'object' ? true : false}
                title={typeof(row.xml) == 'object' ? 'No se ha recibido el XML por parte del proveedor, por lo que no se puede generar el PDF' : ''}
                onClick={() => window.open(GetPdfCompra(row.id), "_blank")}
              >
                PDF
              </Dropdown.Item>

              {
                (typeof(row.answer) == 'object') ? (
                  <>
                    <Dropdown.Divider/>
                    <Dropdown.Item
                      onClick={ () => {
                        HandleClick(1, row);
                      }}
                    >
                      <p><Icon className="fas fa-thumbs-up text-success" /> Aceptar</p>
                    </Dropdown.Item>
                    <Dropdown.Divider/>
                    <Dropdown.Item
                      onClick={ () => {
                        HandleClick(0, row);
                      }}
                    >
                      <p><Icon className="fas fa-thumbs-down text-danger" /> Rechazar </p>
                    </Dropdown.Item>
                  </>
                ) : null
              }
            </DropdownButton>
          </div>
        );
      },
      name: 'Acciones',
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const getDtes = async () => {
    // CrudDtesRecibidos()
    //   .then((data) => {
    //     console.log("respuesta data", data)
    //     setData(data.data.data.dtes.data);
    //   })
    //   .catch(() => {
    //     setAlertClass('danger');
    //     setStatus('Error de conexion con la API');
    //   });
      setLoading(true)
      await CrudDtesRecibidos({page: page, per_page: perPage})
      .then((data) => {
        setData(data.data.data.dtes.data);
        setPerPage(data.data.data.dtes.per_page)
        //setTotalRows(data.data.data.total_rows)
        setTotalRows(100)
        setLoading(false)
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de conexion con la API');
        setLoading(false)
      });
      setLoading(false)
  }

  if(reload){
    setReload(false);
		getDtes();
  }

  const handleChange = (state) => {
    (state.selectedRows.length > 0) ? setIsSelected(true) : setIsSelected(false);
    setRowSelected([...state.selectedRows]);
  };

  const handleCheckXml= (row) => {
    return  (typeof(row.answer) == 'object') ? false : true;
  };

  const massiveResponse = (aceptar) => {
    const dtes = rowSelected.map( row => row.id);
    AceptacionRechazoMasivo(dtes, aceptar)
      .then(({data}) => {
        Swal.fire(data.message, '', (data.success ? 'success' : 'danger'));
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de conexion con la API');
      });
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
  					    <div className="col-11">
                  <h3 className="card-title">Documentos Recibidos</h3>
  					    </div>
                <div className="col-1 float-right">
                  <DropdownButton id="dropdown-basic-button" title="Acciones" menuAlign="right">
                    <>
                      <Dropdown.Divider/>
                      <Dropdown.Item
                        disabled={!isSelected}
                        onClick={ () => {
                          massiveResponse(1);
                        }}
                      >
                        <p><Icon className="fas fa-thumbs-up text-success" /> Aceptar</p>
                      </Dropdown.Item>
                      <Dropdown.Divider/>
                      <Dropdown.Item
                        disabled={!isSelected}
                        onClick={ () => {
                          massiveResponse(0);
                        }}
                      >
                        <p><Icon className="fas fa-thumbs-down text-danger" /> Rechazar </p>
                      </Dropdown.Item>
                    </>
                  </DropdownButton>
    					  </div>
              </div>
              <div className="card-body">
                {
                  status ? (
                      <div className={`alert alert-${alertClass} alert-dismissible`}>
                          {status}
                      </div>
                  ) : null
                }
                <div className='row d-flex align-items-end'>
                  <div className="form-group col-auto">
                    <label>Folio</label>
                    <input type="number" value={folio} onChange={(e) => setFolio(e.target.value)} className="form-control" placeholder="12345" />
                  </div>
                  <div className="form-group col-auto">
                    <label>Rut Emisor</label>
                    <input type="text" value={rut_emisor} onChange={(e) => setRut_emisor(e.target.value)} className="form-control" placeholder="11111111-1" />
                  </div>
                  <div className="form-group col-auto">
                    <label>Fecha</label>
                    <input type="date" className="form-control" value={fecha} onChange={(e) => setFecha(e.target.value)} placeholder="2021-10-26" />
                  </div>
                  <div className="form-group col-auto">
                    <button type="button" onClick={handleFilters} className="btn btn-block btn-primary">Buscar</button>
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  data={data}
                  selectableRows={true} // add for checkbox selection
                  onSelectedRowsChange={handleChange}
                  selectableRowDisabled={handleCheckXml}
                  noDataComponent="No existen datos para mostrar"
                  pagination
                  paginationComponentOptions={paginationComponentOptions}
                  paginationServer
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationTotalRows={totalRows}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalLoading show={loading} onHide={() => setLoading(false)} />
    </>
  )
}
