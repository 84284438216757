import React, { useEffect, useState } from 'react';
import { Button} from 'react-bootstrap';
import { useFormik, FormikProvider, Field, FieldArray } from 'formik';
import * as Yup from "yup";
import { v4 as uuidv4 } from 'uuid';
import {TiposDtes, SentDTE} from '../components/common/Crud/Dtes';
import Table from 'react-bootstrap/Table'
import Icon, { Spinner } from '../helpers/Icon'

export const SentDte = props => {

  const [loading, setLoading] = useState(false);
  const [typesDtes, setTypesDtes] = useState([]);
  const [alertClass, setAlertClass] = useState('danger');
  const [recharge, setRecharge] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const exampleItem = {
    name: '',
    quantity: 1,
    price: 1,
    discount: 0,
  };

	const initialValues = {
		dte_type: 33,
    RUTRecep: '',
    RznSocRecep: '',
    GiroRecep: '',
    DirRecep: '',
    CmnaRecep: '',
    ref_id: uuidv4(),
    email_recept: '',
    send_mail: 0,
    items: [exampleItem]
	}

  const Schema = Yup.object().shape({
    dte_type: Yup.number().positive().integer().required('El tipo de documento es requerido'),
    RUTRecep: Yup.string().required('El Rut del receptor es requerido'),
    RznSocRecep: Yup.string().required('La Razon Social es requerida'),
    GiroRecep: Yup.string().required('El Giro del receptor es requerida'),
    DirRecep: Yup.string().required('La Direccion del receptor es requerida'),
    CmnaRecep: Yup.string().required('La Comuna del receptor es requerida'),
    email_recept: Yup.string().required('El E-mail del receptor es requerido'),
    ref_id: Yup.string().required(''),
    items: Yup.array()
      .of(
        Yup.object().shape({
            name: Yup.string().required('Requerido'),
            quantity: Yup.string().min(1, 'Debe ser mayor a 0').required('Requerido'),
            price: Yup.string().min(1, 'Debe ser mayor a 0').required('Requerido'),
            discount: Yup.string().min(0, 'Debe ser igual o mayor a 0').required('Requerido'),
        })
      )
      .required('Debe tener al menos un item')
  });

	const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      SentDTE(values)
      .then(({data}) => {
        enableLoading();
        setSubmitting(true);
          if(data.success){
            setAlertClass('info');
            setStatus("Documento creado correctamente.")
          }else{
            setAlertClass('danger');
            setStatus(data.message);
          }
          disableLoading();
          setSubmitting(false);
          setRecharge(!recharge)

          resetForm();
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de comunicación');
        setSubmitting(false);
        disableLoading();
      })
    }
  });

	useEffect(() => {
    console.log("UUID: ", initialValues.ref_id)
    TiposDtes()
      .then(({data}) => {
        setTypesDtes(data);
      })
      .catch(() => {
        setAlertClass('danger');
        formik.setStatus('Error de conexion con la API');
      })
  }, [recharge])

  return (
    <FormikProvider value={formik}>
      <div className="container-fluid">
  			<div className="row">
  		  	<div className="col-12">
  					<div className="card">
  			  		<div className="card-header container-fluid">
  				  		<div className="row">
      				    	<div className="col-11">
      				        	<h3 className="w-75 p-3">Generar nuevo DTE</h3>
      				    	</div>
      					</div>
  			  		</div>
  			  		<div className="card-body">
                <form onSubmit={formik.handleSubmit}>
                {
                    formik.status ? (
                        <div className={`alert alert-${alertClass} alert-dismissible`}>
                            {formik.status}
                        </div>
                    ) : null
                }
  							  <div className="row">
  							  	<div className="col-6">
                      <div className="col-12">
                        <div className="form-group">
                          <label>Tipo Documento</label>
                          <Field
                            as="select"
                            name="dte_type"
                            className={`form-control form-control-sm ${formik.errors.dte_type &&  ('is-invalid')}`}
                          >
					  		  					{
					  		  						typesDtes.map( (tipo) => {
					  		  							return <option key={tipo.id} value={tipo.code}> {tipo.type} </option>
					  		  						})
					  		  					}
					  		  				</Field>
                          <input
                            style={{display:"none"}}
                            type='text'
                            {...formik.getFieldProps("ref_id")}
                          />
                          <input
                            style={{display:"none"}}
                            type='text'
                            {...formik.getFieldProps("send_now")}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Razón social receptor</label>
                          <Field
                            type="text"
                            name="RznSocRecep"
                            className={`form-control form-control-sm ${formik.errors.RznSocRecep &&  ('is-invalid')}`}
                            placeholder="Coca Cola"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Dirección receptor</label>
                          <Field
                            type="text"
                            name="DirRecep"
                            className={`form-control form-control-sm ${formik.errors.DirRecep &&  ('is-invalid')}`}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>E-mail receptor</label>
                          <Field
                            type="email"
                            name="email_recept"
                            className={`form-control form-control-sm ${formik.errors.email_recept &&  ('is-invalid')}`}
                          />
                        </div>
                      </div>
  			  			  	</div>
                    <div className="col-6">
                      <div className="col-12">
                        <div className="form-group">
                          <label>Rut receptor</label>
                          <Field
                            type="text"
                            name="RUTRecep"
                            className={`form-control form-control-sm ${formik.errors.RUTRecep &&  ('is-invalid')}`}
                            placeholder="66666666-6"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Giro receptor</label>
                          <Field
                            type="text"
                            name="GiroRecep"
                            className={`form-control form-control-sm ${formik.errors.GiroRecep &&  ('is-invalid')}`}
                            placeholder="Venta al por mayor"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label>Comuna receptor</label>
                          <Field
                            type="text"
                            name="CmnaRecep"
                            className={`form-control form-control-sm ${formik.errors.CmnaRecep &&  ('is-invalid')}`}
                          />
                        </div>
                      </div>
  			  			  	</div>
  			  			  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">Items</label>
                        <div className="input-group col-12">
                          <Table borderless responsive size="sm">
                            <thead>
                              <tr>
                                <th>Nombre</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Descuento $</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray name="items">
                                {
                                  ({remove, push })=> (
                                    <>
                                      {
                                        formik.values.items.length > 0 &&
                                        formik.values.items.map((item, index) => (
                                          <tr  key={index}>
                                            <td>
                                              <Field
                                                name={`items.${index}.name`}
                                                type="text"
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`items.${index}.quantity`}
                                                type="number"
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`items.${index}.price`}
                                                type="number"
                                              />
                                            </td>
                                            <td>
                                              <Field
                                                name={`items.${index}.discount`}
                                                type="number"
                                              />
                                            </td>
                                            <td>
                                              <button
                                                type="button"
                                                className="btn btn-block btn-outline-danger btn-sm"
                                                onClick={() => remove(index)}
                                              >
                                                <Icon className="fa fas fa-trash" />
                                              </button>
                                            </td>
                                          </tr>
                                        ))
                                      }
                                      <tr>
                                        <td colSpan="5">
                                          <button className="btn btn-primary" style={{marginTop:"10px"}}
                                            onClick={() => push(exampleItem)}
                                          >
                                            Agregar Item
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  )
                                }
                              </FieldArray>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        id="generarDte"
                        className="btn btn-success btn-block"
                        type="submit"
                        disabled={formik.isSubmitting}
                      >
                        {loading && <Spinner/>}
                        Generar
                      </button>
                    </div>
                  </div>
                </form>
  			  		</div>
  					</div>
  		  	</div>
  			</div>
  	  </div>
    </FormikProvider>
  )
}
