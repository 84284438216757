import { Button, Modal } from "react-bootstrap";


export default (props) => {

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        {props.title ?? <Modal.Title>{props.title}</Modal.Title>}
      </Modal.Header>
      
      <Modal.Body>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Cerrar</Button>
      </Modal.Footer>
    </Modal>
  );
      
}
