import React, { useEffect, useState } from 'react';
import {SetExchangeEmail , ExchangeEmail} from '../../components/common/Crud/Companies';
import { useFormik, FormikProvider, Field } from 'formik';
import * as Yup from "yup";
import { Spinner } from '../../helpers/Icon';


export const ModalExchangeEmail = props => {

  const [loading, setLoading] = useState(false);
  const [alertClass, setAlertClass] = useState('danger');
  const [initialValues, setInitialValues] = useState({
		id: '',
		host: '',
		port: '',
		validate_cert: '',
		username: '',
		password: '',
		encryption: '',
		protocol: '',
	});

  const getExchangeEmail = () =>{
    ExchangeEmail()
			.then( ({data}) => {
        setInitialValues(data);
        if(typeof(data) != 'undefined'){
          formik.setFieldValue('id', data.id);
          formik.setFieldValue('host', data.host);
          formik.setFieldValue('port', data.port);
          formik.setFieldValue('validate_cert', data.validate_cert);
          formik.setFieldValue('username', data.username);
          formik.setFieldValue('password', data.password);
          formik.setFieldValue('encryption', data.encryption);
          formik.setFieldValue('protocol', data.protocol);
        }
			})
			.catch(() => {
        setAlertClass('danger');
        formik.setStatus('Error de conexion con la API');
      });
  }

	useEffect(() => {
		getExchangeEmail()
	}, [])

	const Schema = Yup.object().shape({
    id: Yup.number().positive().integer(),
		host: Yup.string().required(),
		port: Yup.number().integer().positive().required(),
		validate_cert: Yup.boolean().required(),
		username: Yup.string().required(),
		password: Yup.string().required(),
		encryption: Yup.string().required(),
		protocol: Yup.string().required(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

	const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      SetExchangeEmail(values)
        .then( ({data}) => {
          if(data.success){
            setAlertClass('info');
          }else{
            setAlertClass('danger');
          }
          setStatus(data.message);
        })
        .catch(()=>{
          setAlertClass('danger');
          setStatus('Error de comunicación');
        });
      disableLoading();
      setSubmitting(false);
    }
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

	return (
		<FormikProvider value={formik}>
	    <div>
      	<form onSubmit={formik.handleSubmit}>
      	  {
      	      formik.status ? (
      	          <div className={`alert alert-${alertClass} alert-dismissible`}>
      	              {formik.status}
      	          </div>
      	      ) : null
      	  }
      		<div className="container">
      		  <div className="row">
							<div className="col-12">
								<div className="col-12">
      	      	  <div className="form-group">
      	      	    <label htmlFor="exampleInputFile">Host </label>
      	      	    <div className="input-group">
      	      	      <Field className={`form-control col-12 ${getInputClasses( "host" )}`} type="text" name="host" />
                      <Field style={{display:'none'}} type ="text" name="id" />
      	      	    </div>
      	      	  </div>
      	      	</div>
								<div className="col-12">
      	      	  <div className="form-group">
      	      	    <label htmlFor="exampleInputFile">Puerto</label>
      	      	    <div className="input-group">
      	      	      <Field className={`form-control col-12 ${getInputClasses( "port" )}`} type="text" name="port" />
      	      	    </div>
      	      	  </div>
      	      	</div>
								<div className="col-12">
      	      	  <div className="form-group">
      	      	    <label htmlFor="exampleInputFile">Usuario</label>
      	      	    <div className="input-group">
      	      	      <Field className={`form-control col-12 ${getInputClasses( "username" )}`} type="string" name="username" />
      	      	    </div>
      	      	  </div>
      	      	</div>
                <div className="col-12">
      	      	  <div className="form-group">
      	      	    <label htmlFor="exampleInputFile">Contraseña</label>
      	      	    <div className="input-group">
      	      	      <Field className={`form-control col-12 ${getInputClasses( "password" )}`} type="string" name="password" />
      	      	    </div>
      	      	  </div>
      	      	</div>
                <div className="col-12">
      	      	  <div className="form-group">
      	      	    <label htmlFor="exampleInputFile">Encriptación</label>
      	      	    <div className="input-group">
      	      	      <Field className={`form-control col-12 ${getInputClasses( "encryption" )}`} type="string" name="encryption" />
      	      	    </div>
      	      	  </div>
      	      	</div>
                <div className="col-12">
      	      	  <div className="form-group">
      	      	    <label htmlFor="exampleInputFile">Protocolo</label>
      	      	    <div className="input-group">
      	      	      <Field className={`form-control col-12 ${getInputClasses( "protocol" )}`} type="string" name="protocol" />
      	      	    </div>
      	      	  </div>
      	      	</div>
                <div className="col">
      		          <div className="form-group">
      		            <label htmlFor="exampleInputFile">Validar Certificado</label>
      		            <div className="input-group">
      		                <Field type ="checkbox" name="validate_cert" />
      		            </div>
      		          </div>
      		      </div>
								<div className="col-12">
      	      	  <div className="form-group">
      	      	    	<button className="btn btn-success btn-block" type="submit"> {loading && <Spinner/>} Guardar </button>
      	      	  </div>
      	      	</div>
      	      </div>
						</div>
					</div>
        </form>
	  	</div>
		</FormikProvider>
	)
}
