import React from 'react';
import { Body } from './Body'

export const Layout = props => {

    return (
        <div className="login-page">
            <Body />
        </div>
    );
}
