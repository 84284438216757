import { Form, Formik, useFormik } from "formik";
import { useState } from "react";
import { Button} from "react-bootstrap";
import * as Yup from "yup";
import {upload} from '../../components/common/Crud/Cafs';


export default (props) => {

  const SUPPORTED_FORMATS = ['application/xml'];

  const [file, setFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertClass, setAlertClass] = useState('danger');

  const initialValues = {
    file_caf: '',
  };

  const Schema = Yup.object().shape({
    file_caf: Yup.mixed().required('El archivo caf es requerido')
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);
      if(file){
        const fileForm = new FormData();
        fileForm.append('file', file);
        upload(fileForm)
        .then(({ data }) => {
          if(data.success){
            setAlertClass('info');
            setStatus('Archivo cargado correctamente.');
          }else{
            setAlertClass('danger');
            setStatus(data.message);
          }
        })
        .catch(() => {
          setAlertClass('danger');
          setStatus('Error al cargar el archivo.');
        });
      }else{
        setAlertClass('danger');
        setStatus('Error en el archivo');
      }
      setSubmitting(false);
      disableLoading();
    }
  });

  const handleSetFile = (e) =>{
    setFile(e.currentTarget.files[0]);
  }

  return (
    <div>
        <form onSubmit={formik.handleSubmit}>
        {
            formik.status ? (
                <div className={`alert alert-${alertClass} alert-dismissible`}>
                    {formik.status}
                </div>
            ) : null
        }
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="exampleInputFile">Cargar Folio</label>
                <div className="input-group">
                    <input
                        type="file"
                        id="file_caf"
                        name="file_caf"
                        onChange={(e) => {
                          handleSetFile(e)
                          formik.handleChange(e)
                        }}
                    />
                </div>
              </div>
            </div>
          </div>
          <div className="row p-5 justify-content-end">
            <div className="col-1">
              <button
                id="cargarFolio"
                className="btn btn-success"
                type="submit"
                disabled={formik.isSubmitting}
              >
                Cargar
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </div>
        </div>
        </form>
    </div>
  );

}
