import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import {DropdownButton, Dropdown} from 'react-bootstrap';
import {CompanySetting} from '../../components/common/Crud/Companies';
import { ModalConfiguracion } from './ModalConfiguracion';
import { ModalExchangeEmail } from './ModalExchangeEmail';
import ModalLayaut from '../modal/ModalLayaut';

export const ConfiguracionPage = props => {

	const [modalShowEmail, setModalShowEmail] = useState(false);
	const [modalShow, setModalShow] = useState(false);
  const [companySetting, setCompanySetting] = useState(false)
  const [reload, setReload] = useState(true)
  const [modalOptions, setModalOptions] = useState({
    title: '',
    size: 'md'
  });
  const [status, setStatus] = useState(false);
  const [alertClass, setAlertClass] = useState('info');

	const updateReload = () => {
		setReload(true);
	}

	const columns = [
	  {
			name: 'Razon Social',
			selector: 'company.business_name',
			sortable: true,
	  },
    {
			name: 'Actividad',
			selector: 'actividad',
			sortable: true,
	  },
		{
			name: 'Template PDF',
			selector: 'templates_pdf.name',
			sortable: true,

	  },
    {
			name: 'Certificado',
			selector: 'company.certificado',
			sortable: true,
      cell: row => <> {row.company.digital_certificate ? 'Si' : 'No' } </>,
	  },
    {
			name: 'Logo',
			selector: 'company.logo',
			sortable: true,
      cell: row => <> {row.company.logo ? 'Si' : 'No' } </>,
	  },
		{
			cell: row => {
				return (
					<div>
            <DropdownButton id="dropdown-basic-button" title="">
              <>
                <Dropdown.Item
                  onClick={ () => {
                    setModalOptions({title:'Configuración de la Empresa', size: 'xl'})
                    setModalShow(true);
                  }}
                >
                 Datos Empresa
                </Dropdown.Item>
              </>
              <>
                <Dropdown.Divider/>
                <Dropdown.Item
                  onClick={ () => {
                    setModalOptions({title:'Configuración Email de intercambio'})
                    setModalShowEmail(true);
                  }}
                >
                  Email de Intercambio
                </Dropdown.Item>
              </>
            </DropdownButton>
					</div>
				);
			},
			name: 'Acciones',
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		}
	];

    const getCompanySetting = () =>{
      CompanySetting()
				.then( ({data}) => {
					setCompanySetting(data);
				})
				.catch(() => {
          setAlertClass('danger');
          setStatus('Error de conexion con la API');
        });
    }

	if(reload){
		getCompanySetting();
		setReload(false);
	}

	return (
		<>
	  	<div className="container-fluid">
				<div className="row">
			  	<div className="col-12">
						<div className="card">
				  		<div className="card-header container-fluid">
					  		<div className="row">
    					    	<div className="col-11">
    					        	<h3 className="w-75 p-3">Configuración de la Empresa</h3>
    					    	</div>
    						</div>
				  		</div>
				  		<div className="card-body">
                {
                  status ? (
                      <div className={`alert alert-${alertClass} alert-dismissible`}>
                          {status}
                      </div>
                  ) : null
                }
								<div className="row">
									<div className="col">
										<DataTable
								  	 columns={columns}
								  	 data={companySetting}
                     noDataComponent="No existen datos para mostrar"
										/>
				  				</div>
				  			</div>
				  		</div>
						</div>
			  	</div>
				</div>
	  	</div>
			<ModalLayaut {...modalOptions} show={modalShow} onHide={() => setModalShow(false)}>
        <ModalConfiguracion
					companySetting={companySetting}
					setReload={updateReload}
				/>
      </ModalLayaut>
			<ModalLayaut {...modalOptions} show={modalShowEmail} onHide={() => setModalShowEmail(false)}>
        <ModalExchangeEmail/>
      </ModalLayaut>
		</>
	)
}
