import axios from "axios";

// export const _COMPANY = sessionStorage.getItem('company');

export const USERS = `api/v2/companies/COMPANY/users`;
export const TYPES_USER = `api/v2/types_user`;
export const UPDATE_USERS = `api/v2/companies/COMPANY/users/`;

export async function GetUsers() {
  return await axios.get(USERS);
}

export async function GetTypesUser() {
  return await axios.get(TYPES_USER);
}

export async function UpdateUser(values) {
  return await axios.put(`${UPDATE_USERS}${values.id}`, values);
}

export function Register(values) {
  return axios.post(USERS, values);
}
