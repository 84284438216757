import React from 'react';

import { Layout } from '../components/common/Layout/Login/index';

export const LoginPage = props => {

  return (
    <Layout />
  )
}
