import React from 'react';
import { Header } from './Header';
import { Body } from './Body';
import { Footer } from './Footer';

export const Layout = props => {
    return (
        <>
            <Header />
                <Body {...props}/>
            <Footer />
        </>
    );
}
