import { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { GetGuiasDespacho, GetPdfVenta, FacturarGuiasDespacho } from '../../components/common/Crud/Dtes';
import Swal from 'sweetalert2';

export const ModalFacturacionGuiasDespacho = (props) => {

  const [loading, setLoading] = useState(false);
  const [alertClass, setAlertClass] = useState('danger');
  const [data, setData] = useState([]);
  const [rowSelected, setRowSelected] = useState(undefined);
  const [isSelected, setIsSelected] = useState(false);
  const [status, setStatus] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const columns = [
    {
      name: 'Folio',
      selector: 'FolioRef',
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: 'RznSocRecep',
      sortable: true,
    },
    {
      name: 'Rut',
      selector: 'RUTRecep',
      sortable: true,
    },
    {
      name: 'Total',
      selector: 'MntTotal',
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: 'date',
      sortable: true,
    },
    {
      cell: row => {
        return (
          <div>
            <DropdownButton id="dropdown-basic-button" title="">
              <Dropdown.Item
                onClick={() => window.open(GetPdfVenta(row.id), "_blank")}
              >
                PDF
              </Dropdown.Item>
              <Dropdown.Divider/>
              <Dropdown.Item
                onClick={ () => {
                  facturarOne(row.id)
                }}
              >
                Facturar
              </Dropdown.Item>
            </DropdownButton>
          </div>
        );
      },
      name: 'Acciones',
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleChange = (state) => {
    (state.selectedRows.length > 0) ? setIsSelected(true) : setIsSelected(false);
    setRowSelected([...state.selectedRows]);
  };

  const facturar = () => {
    const dtes = rowSelected.map( row => row.id);
    FacturarGuiasDespacho(dtes)
      .then(({data}) => {
        Swal.fire(data.message, '', (data.success ? 'success' : 'danger'));
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de conexion con la API');
      });
  }

  const facturarOne = (id) => {
    const dtes = {id}
    FacturarGuiasDespacho(dtes)
      .then(({data}) => {
        Swal.fire(data.message, '', (data.success ? 'success' : 'danger'));
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de conexion con la API');
      });
  }

  useEffect(() => {
    GetGuiasDespacho()
      .then(({data}) => {
        if(data.success){
          setData(data.data.data);
        }
      });

  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
    				    <div className="col-11">
    				    </div>
                <div className="col-1 float-right">
                  <Button
                    disabled={!isSelected}
                    onClick={() => {
                      facturar();
                    }}
                  >
                    Facturar
                  </Button>
    				    </div>
              </div>
              <div className="card-body">
                {
                  status ? (
                      <div className={`alert alert-${alertClass} alert-dismissible`}>
                          {status}
                      </div>
                  ) : null
                }
                <DataTable
                  columns={columns}
                  data={data}
                  selectableRows={true} // add for checkbox selection
                  onSelectedRowsChange={handleChange}
                  noDataComponent="No existen datos para mostrar"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
