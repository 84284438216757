import React, { useEffect, useState } from 'react';
import {getReporteEstadoCuenta} from '../../components/common/Crud/Dashboard';
//import { Line } from "react-chartjs-2";

export const DashboardPage = props => {
  const [reporte, setReporte] = useState(false);
  const [data, setData] = useState(undefined);
  const [status, setStatus] = useState(false);
  const [alertClass, setAlertClass] = useState('info')

  useEffect(() => {
    getReporteEstadoCuenta()
      .then(({data: {data}}) => {
        setReporte(data);
        const datos = {
          labels: Object.values(data.monthly_sales.periodo),
          datasets: [
            {
              label: 'Documentos de Ventas Mensuales',
              data: Object.values(data.monthly_sales.ventas),
              fill: false,
              backgroundColor: 'rgb(5, 217, 58)',
              borderColor: 'rgba(5, 297, 58, 0.2)',
            },
            {
              label: 'Documentos de Compras Mensuales',
              data: Object.values(data.monthly_sales.compras),
              fill: false,
              backgroundColor: 'rgb(217, 5, 5)',
              borderColor: 'rgba(217, 5, 5, 0.2)',
            },
          ],
        };
        setData(datos);
      })
      .catch(() => {
        setAlertClass('danger');
        setStatus('Error de conexion con la API');
      });

  }, [])

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
    				    <div className="col-11">
                  <h3 className="card-title">Dashboard</h3>
    				    </div>
              </div>
              <div className="card-body">
                {
                    status ? (
                        <div className={`alert alert-${alertClass} alert-dismissible`}>
                            {status}
                        </div>
                    ) : null
                }
                <div className="row">
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <div className="inner">
                        <h3>{new Intl.NumberFormat("es-CL", {style: "currency", currency: "CLP"}).format(reporte.total_sent_dtes)}</h3>
                        <p>Documentos de Ventas</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-bag" />
                      </div>
                      <span className="small-box-footer" title="Cantidad total de documentos emitidos">cantidad: {reporte.cantidad_sent_dtes}</span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                      <div className="inner">
                        <h3>{new Intl.NumberFormat("es-CL", {style: "currency", currency: "CLP"}).format(reporte.total_received_dtes)}</h3>
                        <p>Documentos de Compras</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-bag" />
                      </div>
                      <span className="small-box-footer" title="Cantidad total de documentos recibidos">cantidad: {reporte.cantidad_received_dtes}</span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                      <div className="inner">
                        <h3>{new Intl.NumberFormat("es-CL").format(reporte.cantidad_sent_dtes_rechazados ?? 0)}</h3>
                        <p>Doc. Ventas Rechazadas</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-bag" />
                      </div>
                      <span className="small-box-footer">&nbsp;</span>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                      <div className="inner">
                        <h3>{new Intl.NumberFormat("es-CL").format(reporte.cantidad_received_dtes_rechazados ?? 0)}</h3>
                        <p>Doc. Compras Rechazadas</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-bag" />
                      </div>
                      <span className="small-box-footer">&nbsp;</span>
                    </div>
                  </div>
                </div>
                {/* <div className="row" >
                  <Line data={data} width={450} height={150}/>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
