import React, { useContext } from 'react';
import { AuthContext } from '../../helpers/auth/AuthContext';
import { Field, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { Button} from "react-bootstrap";
import * as Yup from "yup";
import {UpdateUser, GetTypesUser} from '../../components/common/Crud/Users';
import { Spinner } from "../../helpers/Icon";


export default ({user}) => {

  const [loading, setLoading] = useState(false);
  const [alertClass, setAlertClass] = useState('danger');
  const [tiposUsuario, setTiposUsuario] = useState([])

  const initialValues = {
    ...user,
    old_password: '',
    password: '',
    password_confirmation: '',
    active: user.pivot.active == 1 ? true: false,
    userType: user.userType_code
  };

  const Schema = Yup.object().shape({
    id: Yup.string().required(),
    name: Yup.string().required(),
    old_password: Yup.string(),
    password: Yup.string().when("old_password", {
      is: value => value && value.length > 0,
      then: Yup.string().required(),
      otherwise: Yup.string()
    }),
    password_confirmation: Yup.string().when("password", {
      is: value => value && value.length > 0,
      then: Yup.string().required(),
      otherwise: Yup.string()
    }),
    active: Yup.boolean().required(),
    userType: Yup.string().required(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setSubmitting(true);
      UpdateUser(values)
        .then(({ data }) => {
          if(data.success){
            setAlertClass('info');
            setStatus('Datos actualizados correctamente.');
          }else{
            setAlertClass('danger');
            setStatus(data.message);
          }
        })
        .catch(() => {
          setAlertClass('danger');
          setStatus('Error al actualizar datos.');
        });
      setSubmitting(false);
      disableLoading();
    }
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  useEffect(() => {
    GetTypesUser()
      .then( ({data}) => {
        setTiposUsuario(data);
      })
      .catch(() => {
        setAlertClass('danger');
        formik.setStatus('Error de conexion con la API');
      });
  }, [])

  return (
		<FormikProvider value={formik}>
      <div>
          <form onSubmit={formik.handleSubmit}>
          {
              formik.status ? (
                  <div className={`alert alert-${alertClass} alert-dismissible`}>
                      {formik.status}
                  </div>
              ) : null
          }
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Nombre</label>
                  <div className="input-group">
                    <input
                      type="text"
                      className={`form-control ${getInputClasses( "name" )}`}
                      placeholder="Nombre Completo"
                      name="name"
                      {...formik.getFieldProps("name")}
                    />
        		        <Field style={{display:'none'}} type ="text" name="id" />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Contraseña Actual</label>
                  <div className="input-group">
                    <input
                      type="password"
                      className={`form-control ${getInputClasses( "old_password" )}`}
                      placeholder=""
                      name="old_password"
                      {...formik.getFieldProps("old_password")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Nueva contraseña</label>
                  <div className="input-group">
                    <input
                      type="password"
                      className={`form-control ${getInputClasses( "password" )}`}
                      placeholder=""
                      name="password"
                      {...formik.getFieldProps("password")}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="exampleInputFile">Confirme contraseña</label>
                  <div className="input-group">
                    <input
                      type="password"
                      className={`form-control ${getInputClasses( "password_confirmation" )}`}
                      placeholder=""
                      name="password_confirmation"
                      {...formik.getFieldProps("password_confirmation")}
                    />
                  </div>
                </div>
              </div>
              <div className={`col-12 ${user.userType_code !== 'admin' ? 'd-none' : ''}`}>
      		      <div className="form-group">
      		        <label htmlFor="exampleInputFile">Activo</label>
      		        <div className="input-group">
      		            <Field type ="checkbox" name="active" />
      		        </div>
      		      </div>
      		    </div>
              <div className={`col-12 ${user.userType_code !== 'admin' ? 'd-none' : ''}`}>
      		      <div className="form-group">
      		        <label htmlFor="exampleInputFile">Tipo de Usuario</label>
      		        <div className="input-group">
											 <Field as="select" name="userType">
											 		{
														tiposUsuario.map( (type) => {
															return <option key={type.id} value={type.code}> {type.code} - {type.description} </option>
														})
													}
												</Field>
      		        </div>
      		      </div>
      		    </div>
            </div>
            <div className="row p-5 justify-content-end">
              <div className="col-1">
                <button
                  id="cargarFolio"
                  className="btn btn-success"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {loading && <Spinner/>}
                  Cargar
                </button>
              </div>
            </div>
          </div>
          </form>
      </div>
    </FormikProvider>
  );

}
