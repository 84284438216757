import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { login, resetPassword, verifyToken } from '../../components/common/Crud/Auth';
import { ErrorMessage, Field, FormikProvider, useFormik } from 'formik';
import * as Yup from "yup";
// import { login } from '../../Crud/Auth';
import { Spinner } from '../../helpers/Icon';
// import { AuthContext } from '../../../../helpers/auth/AuthContext';
// import { types } from '../../../../helpers/types';
// import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2'
import { useHistory } from "react-router-dom";

export const ResetPassword = () => {
  
  const history = useHistory();
  let { token } = useParams();

  const [checking, setChecking] = useState(true)
  const [message, setMessage] = useState(null)
  const [alertClass, setAlertClass] = useState('danger');
  const [state, setState] = useState(false)
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(false)
  //const token = match.params.token;

  const validarToken = async () => {
    setChecking(true)
    await verifyToken(token).then((r) => {
      if(r.data.success) {
        setState(r.data.success)
        setUser(r.data.message)
        setAlertClass('info')
        setMessage('Solicitud correcta ✅')
        setTimeout(() => {
          setMessage(null)
        }, 5000);
      }else{
        setAlertClass('danger')
        setState(r.data.success)
        setMessage(r.data.message)
        setTimeout(() => {
          setMessage(null)
          if(!state) {
            history.push("/login");
          }
        }, 5000);
      }
      setChecking(false)
    }).catch((e) => {
      console.log("Error validar token", e)
      setState(false)
      setChecking(false)
      setTimeout(() => {
        setMessage(null)
      }, 5000);
    })
  }

  // const [loading, setLoading] = useState(false);
  // const [alertClass, setAlertClass] = useState('danger');
  // const { dispatch } = useContext( AuthContext );

  const initialValues = {
    password: "",
    password_confirmation: "",
    token: token,
    email: ''
  };

  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('Nueva contraseña es requerida')
      .min(8, "Minimo 3 caracteres")
      .max(30, "Máximo 30 caracteres"),  
    password_confirmation: Yup.string()
      .min(8, "Minimo 3 caracteres")
      .max(30, "Máximo 30 caracteres")
      .required('La confirmación contraseña es requerida'),
  });

  // const enableLoading = () => {
  //   setLoading(true);
  // };

  // const disableLoading = () => {
  //   setLoading(false);
  // };

  // const getInputClasses = (fieldname) => {
  //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
  //     return "is-invalid";
  //   }

  //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
  //     return "is-valid";
  //   }

  //   return "";
  // };

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //enableLoading();
      setLoading(true)
      values.email = user.email
      resetPassword(values)
        .then(({ data }) => {
          setLoading(false)
          Swal.fire({
            title: 'Cambio de contraseña exitoso',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then((isConfirm) => {
            history.push("/login");
          })
          //disableLoading();
          //dispatch({
          //  type: types.login,
          //  payload: access
          //});
          //props.history.replace('/');
          setSubmitting(false)
        })
        .catch(() => {
          //disableLoading();
          setLoading(false)
          setSubmitting(false);
          //setStatus('Error al iniciar sesión');
        });
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  useEffect(() => {
    validarToken()
    return () => {
      //cleanup
    }
  }, [])

  return (
    <>
      <div className="login-page">
        <div className="login-box">
            <div className="login-logo">
              <b>Dte</b>
            </div>
            <div className="card">
              <div className="card-body">
                {
                  checking &&
                  <div className="row justify-content-center">
                    <div className="col-auto text-center">
                      <Spinner />
                      <br></br>
                      <span>Validando...</span>
                    </div>
                  </div>
                }
                {
                  message !== null &&
                  <div className={`alert alert-${alertClass} alert-dismissible`}>
                      {message}
                  </div>
                }
                {
                  formik.status ? (
                    <div className={`alert alert-danger alert-dismissible`}>
                      {formik.status}
                    </div>
                  ) : null
                }
                {
                  state &&
                  <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                      <Field style={{display:'none'}} type ="text" name="id" />
                      <div className="form-group">
                        <label>Contraseña</label>
                        <input
                          type="password"
                          className={`form-control ${getInputClasses( "password" )}`}
                          placeholder="*******"
                          {...formik.getFieldProps("password")} 
                          />
                          <ErrorMessage name="password">
                            { msg => <div style={{ color: 'red' }}>{msg}</div> }
                          </ErrorMessage>
                      </div>
                      <div className="form-group">
                        <label>Confirmación contraseña</label>
                        <input
                          type="password"
                          className={`form-control ${getInputClasses( "password_confirmation" )}`} 
                          placeholder="*******" 
                          {...formik.getFieldProps("password_confirmation")}
                          />
                          <ErrorMessage name="password_confirmation">
                            { msg => <div style={{ color: 'red' }}>{msg}</div> }
                          </ErrorMessage>
                      </div>
                      <div className="row float-right">
                        <div className="col-1">
                          <button
                            className="btn btn-success"
                            type="submit"
                            disabled={formik.isSubmitting}
                          >
                            {loading && <Spinner/>}
                            Guardar
                          </button>
                        </div>
                      </div>
                    </form>
                  </FormikProvider>
                }
              </div>
            </div>
        </div>
      </div>

    </>
  )
}
