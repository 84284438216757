import React, { Fragment } from 'react';

export const Footer = props => {
    return (
        <>
          <footer className="main-footer">
            <strong>Copyright © 2021 <a href="https://linets.cl" target="_blank">Linets SpA Chile</a>. </strong>
            Todos los derechos reservados.
          </footer>
          <aside className="control-sidebar control-sidebar-dark">
          </aside>
        </>

    )
}
